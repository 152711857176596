<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.renewalStatistics[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <b-tabs v-model="tabFilters" position="is-centered">
        <b-tab-item>
          <template #header>
            <b-icon icon="filter" pack="fas" type="is-info"></b-icon>
            <b>{{ i18n.advancedSearch[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="`LoginID/${i18n.user[iso]}`"
              >
                <b-input
                  v-model="advancedFilters.LoginID"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.name[iso]">
                <b-input
                  v-model="advancedFilters.Nombre"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <!-- <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.lastname[iso]">
                <b-input
                  v-model="advancedFilters.ApellidoPaterno"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div> -->
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.renovationDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaRenovacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="'Club'">
                <b-select v-model="advancedFilters.club" expanded>
                  <option :value="null"></option>
                  <option
                    v-for="club in clubesName"
                    :value="club._id"
                    :key="club._id"
                  >
                    {{ club.Nombre }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.agent[iso]">
                <b-select expanded v-model="advancedFilters.agent">
                  <option :value="null"></option>
                  <option
                    v-for="user in staff"
                    :value="user._id"
                    :key="user._id"
                  >
                    {{ user.Nombre }} {{ user.Apellido }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <!-- <div class="column is-8"></div> -->
            <div class="column is-1">
              <b-button
                icon-left="eraser"
                icon-pack="fas"
                type="is-danger is-light"
                @click="clearAdvancedSearch"
                expanded
              ></b-button>
            </div>
            <div class="column is-2">
              <b-button
                icon-left="search"
                icon-pack="fas"
                type="is-info"
                @click="advancedSearch(false)"
                expanded
                >{{ i18n.search[iso] }}</b-button
              >
            </div>
            <div class="column is-1">
              <b-button
                icon-left="file-export"
                icon-pack="fas"
                type="is-primary"
                :loading="loadingExport"
                @click="advancedSearch(true)"
                expanded
              ></b-button>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <hr />
      <div class="content">
        <h5>{{ allLoading || !Volumen ? "..." : Volumen }}</h5>
      </div>
      <hr />
      <b-tabs v-model="tabFilters2" position="is-centered">
        <b-tab-item>
          <template #header>
            <b-icon icon="cubes" pack="fas" type="is-dark"></b-icon>
            <b>{{ i18n.concentrated[iso] }}</b>
          </template>
          <div class="columns">
            <div class="column is-3">
              <p class="subtitle">{{ i18n.totals[iso] }}</p>
              <b-table
                :data="Totales"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :bordered="isBordered"
                :striped="true"
                :narrowed="isNarrowed"
                :hoverable="true"
                :loading="allLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
              >
                <b-table-column
                  field="Renovaciones"
                  :label="i18n.renewals[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Renovaciones }}
                </b-table-column>
                <b-table-column
                  field="Totales"
                  :label="i18n.total[iso]"
                  centered
                  v-slot="props"
                >
                  {{ formatCurrency(props.row.Totales) }}
                </b-table-column>
              </b-table>
            </div>
            <div class="column is-6">
              <p class="subtitle">{{ i18n.totalsPerYear[iso] }}</p>
              <b-table
                :data="TotalesxYears"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :bordered="isBordered"
                :striped="true"
                :narrowed="isNarrowed"
                :hoverable="true"
                :loading="allLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
              >
                <b-table-column
                  field="YearsRenovados"
                  :label="i18n.renewedYears[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.YearsRenovados }}
                </b-table-column>
                <b-table-column
                  field="Renovaciones"
                  :label="i18n.renewals[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Renovaciones }}
                </b-table-column>
                <b-table-column
                  field="Monto"
                  :label="i18n.amount[iso]"
                  centered
                  v-slot="props"
                >
                  {{ formatCurrency(props.row.Monto) }}
                </b-table-column>
              </b-table>
            </div>
            <div class="column is-3">
              <p class="subtitle">{{ i18n.shippingByAgent[iso] }}</p>
              <b-table
                :data="EnviosxAgente"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :bordered="isBordered"
                :striped="true"
                :narrowed="isNarrowed"
                :hoverable="true"
                :loading="allLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
              >
                <b-table-column
                  field="Agente"
                  :label="i18n.agent[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Agente }}
                </b-table-column>
                <b-table-column
                  field="Envios"
                  :label="i18n.shipments[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Envios }}
                </b-table-column>
              </b-table>
            </div>
          </div>
          <div class="columns">
            <div class="column is-8" style="max-height: 600px">
              <p class="subtitle">{{ i18n.totalsPerAgent[iso] }}</p>
              <b-table
                :data="TotalesxAgente"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :bordered="isBordered"
                :striped="true"
                :narrowed="isNarrowed"
                :hoverable="true"
                :loading="allLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
              >
                <b-table-column
                  field="Agente"
                  :label="i18n.agent[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Agente }}
                </b-table-column>
                <b-table-column
                  field="Years"
                  :label="i18n.years[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Years }}
                </b-table-column>
                <b-table-column
                  field="Renovaciones"
                  :label="i18n.renewals[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Renovaciones }}
                </b-table-column>
                <b-table-column
                  field="Multiple"
                  :label="i18n.multiple[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Multiple }}
                </b-table-column>
                <b-table-column
                  field="Promedio"
                  :label="i18n.average[iso]"
                  centered
                  v-slot="props"
                >
                  {{ formatCurrency(props.row.Promedio) }}
                </b-table-column>
                <b-table-column
                  field="Monto"
                  :label="i18n.amount[iso]"
                  centered
                  v-slot="props"
                >
                  {{ formatCurrency(props.row.Monto) }}
                </b-table-column>
              </b-table>
            </div>
            <div class="column is-4">
              <p class="subtitle">{{ i18n.deferredPaymentsPerAgent[iso] }}</p>
              <b-table
                :data="PagosDiferidosxAgente"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :bordered="isBordered"
                :striped="true"
                :narrowed="isNarrowed"
                :hoverable="true"
                :loading="allLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
              >
                <b-table-column
                  field="Agente"
                  :label="i18n.agent[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Agente }}
                </b-table-column>
                <!-- <b-table-column
              field="Years"
              :label="i18n.yearsToRenew[iso]"
              centered
              v-slot="props"
            >
              {{ props.row.Years }}
            </b-table-column> -->
                <b-table-column
                  field="Renovaciones"
                  :label="i18n.paymentsDeferred[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Diferidos }}
                </b-table-column>
                <b-table-column
                  field="Monto"
                  :label="i18n.totals[iso]"
                  centered
                  v-slot="props"
                >
                  {{ formatCurrency(props.row.Monto) }}
                </b-table-column>
              </b-table>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item>
          <template #header>
            <b-icon icon="cube" pack="fas" type="is-dark"></b-icon>
            <b>{{ i18n.detailed[iso] }}</b>
          </template>
          <div class="columns">
            <div class="column is-12">
              <b-table
                :data="Detallado"
                :paginated="isPaginated"
                :per-page="perPage"
                :current-page.sync="currentPage"
                :pagination-simple="isPaginationSimple"
                :pagination-position="paginationPosition"
                :default-sort-direction="defaultSortDirection"
                :pagination-rounded="isPaginationRounded"
                :sort-icon="sortIcon"
                :sort-icon-size="sortIconSize"
                aria-next-label="Next page"
                aria-previous-label="Previous page"
                aria-page-label="Page"
                aria-current-label="Current page"
                :bordered="isBordered"
                :striped="true"
                :narrowed="isNarrowed"
                :hoverable="true"
                :loading="allLoading"
                :focusable="isFocusable"
                :mobile-cards="hasMobileCards"
              >
                <b-table-column
                  field="SolicitudId"
                  :label="i18n.folio[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.SolicitudId }}
                </b-table-column>

                <b-table-column
                  field="LoginID"
                  :label="i18n.LoginID[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.LoginID }}
                </b-table-column>

                <b-table-column
                  field="Anualidades"
                  :label="i18n.years[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Anualidades }}
                </b-table-column>

                <b-table-column
                  field="FechaPago"
                  :label="i18n.payDay[iso]"
                  centered
                  v-slot="props"
                >
                  {{ formatDate(props.row.FechaPago) }}
                </b-table-column>

                <b-table-column
                  field="Agente"
                  :label="i18n.agent[iso]"
                  centered
                  v-slot="props"
                >
                  {{ props.row.Agente }}
                </b-table-column>

                <b-table-column
                  field="Cargo"
                  :label="i18n.amount[iso]"
                  centered
                  v-slot="props"
                >
                  $ {{ props.row.Cargo }} USD
                </b-table-column>
              </b-table>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
    </section>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n, { agent, loading } from "../../utils/i18n";

import ModalEditMember from "../ModalEditMember.vue";
// import ModalCommentMember from "./ModalCommentMember.vue";

const today = new Date();

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      total: 0,
      loadingExport: false,

      //Reporteria
      Totales: [],
      loadingTotales: false,
      TotalesxAgente: [],
      loadingTotalesxAgente: false,
      TotalesxYears: [],
      loadingTotalesxYears: false,
      EnviosxAgente: [],
      loadingEnviosxAgente: false,
      PagosDiferidosxAgente: [],
      allLoading: false,
      Detallado: [],
      Volumen: null,

      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      //Filters
      tabFilters: 0,
      tabFilters2: 0,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      clubesName: [],
      staff: [],
      selectedStaff: null,
      loadingClubes: false,
      itemsFilters: [],
      checkedRows: [],
      advancedFilters: {
        Nombre: null,
        LoginID: null,
        FechaRenovacion: [],
        club: null,
        agent: null,
      },
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        FechaCompra: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },

      /**
       * Members
       */

      members: [],
      loadingMembers: false,

      /**
       * Modal
       */

      isActiveModalEditMember: false,
      dataModalEditMember: null,
      isActiveModalCommentMember: false,
      memberIdModalCommentMember: null,

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
    };
  },
  async created() {
    this.advancedFilters.FechaRenovacion.push(
      new Date(moment().startOf("month"))
    );
    this.advancedFilters.FechaRenovacion.push(
      new Date(moment().endOf("month"))
    );

    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //Tabs
    const tabFilters = localStorage.getItem("tabFilters");
    if (tabFilters === 1 || tabFilters === "1") this.tabFilters = 1;

    //Members
    await this.listAllClubes();
    await this.listAllUsersStaff();

    // let stateMembers = localStorage.getItem("members");
    // stateMembers = JSON.parse(stateMembers);
    // console.log("stateMembers", stateMembers);
    // if (
    //   stateMembers &&
    //   stateMembers?.exp &&
    //   stateMembers.exp > moment().valueOf() &&
    //   stateMembers.lastSearchMembers &&
    //   stateMembers.lastSearchMembers !== "null"
    // ) {
    //   this.searchMembers = stateMembers.lastSearchMembers;
    //   this.passValidateSearch = true;
    //   this.listAllMembers({}, true, stateMembers);
    // } else this.advancedSearch(false);

    await this.advancedSearch(false);
  },
  components: {
    ModalEditMember,
    // ModalCommentMember,
  },
  methods: {
    formatCurrency(value) {
      if (!value) return "";
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    },
    async listAllMembers(filters = {}, isState = false, state = {}) {
      try {
        console.log("isState", isState);
        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );
          console.log("members", data);
          this.members = data?.members || [];
          this.total = data.paginate.total;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.loadingMembers = false;

          /**
           * State
           */
          const stateMembers = {
            data: this.members,
            exp: moment().add(20, "minute").valueOf(),
            total: this.total,
            lastSearchMembers: this.lastSearchMembers,
            itemsFilters: this.itemsFilters,
          };
          localStorage.setItem("members", JSON.stringify(stateMembers));
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async reporteria(filters = {}) {
      try {
        this.allLoading = true;
        console.log("reporteria", filters);
        const { data } = await axios.post(
          `${API_URL}/renewals/statistics`,
          filters
        );
        //   console.log("members", data);
        this.Totales = data?.Totales || [];
        this.TotalesxAgente = data?.TotalesxAgente || [];
        this.TotalesxYears = data?.TotalesxYears || [];
        this.EnviosxAgente = data?.EnviosxAgente || [];
        this.PagosDiferidosxAgente = data?.PagosDiferidosxAgente || [];
        this.Detallado = data?.Detallado || [];

        if (data?.Volumen && data?.Volumen.length > 0) {
          const vol = data.Volumen[0];
          const __total = this.formatCurrency(vol?.Volumen);
          const __renewals = vol?.Renovaciones;
          let __text = this.i18n.volumenRenewal.es
            .replace("{{renewals}}", __renewals)
            .replace("{{total}}", __total);
          this.Volumen = __text;
        }

        this.allLoading = false;
      } catch (err) {
        this.allLoading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      this.listAllMembers();
    },
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },
    clearInputSearch() {
      this.searchMembers = null;
      this.passValidateSearch = false;
      this.listAllMembers();
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    openModalEditMember(member) {
      this.dataModalEditMember = member;
      this.isActiveModalEditMember = true;
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    changeTab() {
      localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre",
        });
        this.clubesName = data?.clubes || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      if (isExport) {
        this.$buefy.dialog.confirm({
          message: this.i18n.exportToCsv__msg1[this.iso],
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-success",
          onConfirm: async () =>
            await this.exportAllMembers(this.advancedFilters),
        });
        s;
      } else {
        this.currentPage = 1;
        this.reporteria(this.advancedFilters);
      }
    },
    basicSearch(isExport = false) {
      if (isExport) {
        if (this.searchMembers && this.searchMembers !== "")
          this.exportAllMembers({
            rgx: this.searchMembers,
          });
        else this.exportAllMembers({});
      } else {
        this.currentPage = 1;
        if (this.searchMembers && this.searchMembers !== "")
          this.listAllMembers({
            rgx: this.searchMembers,
          });
      }
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    onPageChange(page) {
      this.currentPage = page;
      // console.log("checkAdvancedSearch()", checkAdvancedSearch());
      if (this.searchMembers && this.searchMembers !== "") {
        this.listAllMembers({
          rgx: this.searchMembers,
        });
      } else if (this.checkAdvancedSearch(false)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.listAllMembers();
      }
    },
    clearAdvancedSearch() {
      this.advancedFilters = {
        Nombre: null,
        LoginID: null,
        FechaRenovacion: [],
        club: null,
        agent: null,
      };
      this.advancedSearch();
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data.ContratoId?.Idioma,
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.ContratoId?.Idioma,
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("LL");
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      console.log("result", result);
      return result.filter((b) => b && b !== "");
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExport = true;
        const { data } = await axios.post(`${API_URL}/renewals/statistics`, {
          ...filters,
          isExport: true,
        });

        if (data?.Totales) await this.createCsv(data?.Totales, "Totales");
        if (data?.TotalesxAgente)
          await this.createCsv(data?.TotalesxAgente, "TotalesxAgente");
        if (data?.TotalesxYears)
          await this.createCsv(data?.TotalesxYears, "TotalesxYears");
        if (data?.PagosDiferidosxAgente)
          await this.createCsv(
            data?.PagosDiferidosxAgente,
            "PagosDiferidosxAgente"
          );
        if (data?.Detallado) await this.createCsv(data?.Detallado, "Detallado");

        this.loadingExport = false;
      } catch (err) {
        this.loadingExport = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    createCsv(data, name) {
      const blob = new Blob([data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.setAttribute("href", url);
      a.setAttribute("download", `${name}.csv`);
      a.click();
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
    async listAllUsersStaff() {
      try {
        // this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/system/list-staff`);
        this.staff = data?.users || [];
        // this.loadingClubes = false;
      } catch (err) {
        // this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de staff",
          type: "is-danger",
        });
      }
    },
    checkContract(a, b) {
      // console.log("row", this.checkedRows);
      return true;
    },
    async activateAllContractsFetch() {
      try {
        // this.loadingClubes = true;
        await axios.put(
          `${API_URL}/catcher/edit-status-membership-massive/contract`,
          {
            contractIds: this.checkedRows.map((c) => c.ContratoId.Id),
          }
        );
        this.$buefy.toast.open({
          message: this.i18n.massiveActivateContracts__msg1[this.iso],
          type: "is-success",
        });
        this.checkedRows = [];
        this.advancedSearch();
        // this.loadingClubes = false;
      } catch (err) {
        // this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error",
          type: "is-danger",
        });
      }
    },
    async activateAllContracts() {
      if (this.checkedRows.length > 0) {
        this.$buefy.dialog.confirm({
          message: this.i18n.massiveActivateContracts__msg2[this.iso].replace(
            "{{total}}",
            this.checkedRows.length
          ),
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-success",
          onConfirm: async () => await this.activateAllContractsFetch(),
        });
      }
    },
    async assignStaffToContractsFetch() {
      if (this.checkedRows.length > 0) {
        try {
          // this.loadingClubes = true;
          await axios.post(`${API_URL}/catcher/assign-staff/contract`, {
            contractIds: this.checkedRows.map((c) => c.ContratoId.Id),
            staff: this.selectedStaff,
          });
          this.$buefy.toast.open({
            message: this.i18n.massiveAssignContracts__msg1[this.iso],
            type: "is-success",
          });
          this.checkedRows = [];
          this.selectedStaff = null;
          this.advancedSearch();
          // this.loadingClubes = false;
        } catch (err) {
          // this.loadingClubes = false;
          console.log(err);
          this.$buefy.toast.open({
            message: "Error",
            type: "is-danger",
          });
        }
      }
    },
    async assignStaffToContracts() {
      if (this.checkedRows.length > 0 && this.selectedStaff) {
        this.$buefy.dialog.confirm({
          message: this.i18n.massiveAssignContracts__msg2[this.iso]
            .replace("{{total}}", this.checkedRows.length)
            .replace("{{staff}}", this.selectedStaff),
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-success",
          onConfirm: async () => await this.assignStaffToContractsFetch(),
        });
      }
    },
    checkSubscription(contract) {
      const now = Date.now();
      if (
        contract &&
        contract?.FechaRenovacion &&
        contract?.FechaRenovacion < now
      )
        return false;
      else return true;
    },
    checkMembership(contract) {
      if (contract && contract?.EstadoMembresia === true) return 1;
      else if (contract && contract?.EstadoMembresia === false) return 2;
      else return 3;
    },
  },
};
</script>
