<template>
  <div class="allColor fontMain" style="height: 100vh">
    <b-navbar shadow spaced centered class="allColor">
      <template #brand>
        <b-navbar-item tag="div">
          <img
            src="https://booking.vacancyrewards.com/css/clientes/vacancyrewards/images/brand-primary.png"
            alt="logo"
            style="width: 150px; max-height: 2.75rem !important"
          />
        </b-navbar-item>
      </template>

      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-light" @click="changeIso">
              {{ iso === "es" ? i18n.english[iso] : i18n.spanish[iso] }}
            </a>
            <a
              class="button"
              style="
                background-color: #0472aa !important;
                color: white !important;
              "
              href="https://members.vacancyrewards.com"
            >
              <strong>{{ i18n.login[iso] }}</strong>
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>

    <div class="columns is-multiline" v-if="siteShow === 'pay' && paymentLink">
      <div class="column is-6" style="padding: 4%">
        <div class="content bordess" style="padding: 4%">
          <div class="columns">
            <div class="column is-7">
              <h2>Plan</h2>
              <p>
                {{ i18n.duration[iso] }}:
                <b
                  >{{
                    paymentLink.AniosRenovacion === 99
                      ? NO_MORE_FEES
                      : paymentLink.AniosRenovacion
                  }}
                  {{
                    paymentLink.AniosRenovacion === 99 ? "" : i18n.years[iso]
                  }}</b
                >
                <br>
                {{ i18n.expiredAt[iso] }}:
                <b>{{
                  paymentLink.AniosRenovacion === 99
                    ? i18n.lifetime[iso]
                    : parseDate(paymentLink.DatosUtiles.FechaExpiracion)
                }}</b>
              </p>
              <!-- <p v-if="paymentLink.DatosUtiles.codeReset">
                {{ i18n.condoned[iso] }}:
                <b
                  >{{ paymentLink.DatosUtiles.YearsCondoned }}
                  {{ i18n.years[iso] }}</b
                >
              </p> -->
              <h3 v-if="paymentLink.DatosUtiles.isDeferred">{{ i18n.paymantDetails[iso] }}</h3>
              <p v-if="paymentLink.DatosUtiles.isDeferred">
                {{ i18n.priceTotal[iso] }}:
                <b
                  >{{
                    paymentLink.DatosUtiles.MontoTotalPagoDiferido
                  }} USD</b
                >
                <br>
                <span v-if="paymentLink.DatosUtiles.discountCupon">
                  {{ i18n.discount[iso] }} ({{ paymentLink.DatosUtiles.codeCupon }}):
                  <b
                    >{{
                      paymentLink.DatosUtiles.discountCupon
                    }} USD</b
                  >
                  <br>
                </span>
                {{ i18n.amountPaid[iso] }}:
                <b
                  >{{
                    paymentLink.DatosUtiles.MontoTotalPagadoDiferido || 0
                  }} USD</b
                >
              </p>
            </div>
            <div class="column is-5">
              <h2>{{ i18n.contract[iso] }}</h2>
              <p>
                {{ i18n.number[iso] }}: <b>{{ paymentLink.contract.Numero }}</b>
                <br>
                LoginID: <b>{{ paymentLink.contract.LoginID }}</b>
                <br>
                {{ i18n.owner[iso] }}:
                <b
                  >{{ paymentLink.memberTitular.Nombre }}
                  {{ paymentLink.memberTitular.ApellidoPaterno }}</b
                >
              </p>
            </div>
          </div>

          <table>
            <tbody>
              <tr>
                <td style="border-width: 0 0 0">{{ i18n.price[iso] }}</td>
                <td style="border-width: 0 0 0; text-align: right">
                  {{ paymentLink.DatosUtiles.priceOriginal }} USD
                </td>
              </tr>
              <tr
                v-if="
                  paymentLink.DatosUtiles &&
                  paymentLink.DatosUtiles.discountBaseYears
                "
              >
                <td style="border-width: 0 0 0">
                  {{ i18n.discount[iso] }} (<b>{{
                    paymentLink.DatosUtiles.discountBaseYears + "%" || ""
                  }}</b
                  >)
                </td>
                <td style="border-width: 0 0 0; text-align: right">
                  -{{ paymentLink.DatosUtiles.discountYears }} USD
                </td>
              </tr>
              <tr
                v-if="
                  paymentLink.DatosUtiles.discountCupon &&
                  !paymentLink.DatosUtiles.isDeferred
                "
              >
                <td style="border-width: 0 0 0">
                  {{ i18n.discount[iso] }} (<b>{{
                    paymentLink.DatosUtiles.codeCupon
                  }}</b
                  >)
                </td>
                <td style="border-width: 0 0 0; text-align: right">
                  -{{ paymentLink.DatosUtiles.discountCupon }} USD
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style="border-width: 0 0 0"><b>Total</b></td>
                <td style="border-width: 0 0 0; text-align: right">
                  <b>$ {{ paymentLink.Monto }} USD</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-6 shadowPay" style="padding: 2%">
        <div id="checkout"></div>
      </div>
    </div>
    <div class="columns is-multiline" v-else-if="siteShow === 'paid'">
      <div class="column is-6" style="padding: 4%">
        <div class="content bordess" style="padding: 4%">
          <div class="columns">
            <div class="column is-8">
              <h2>Plan</h2>
              <p>
                {{ i18n.duration[iso] }}:
                <b
                  >{{
                    paymentLink.AniosRenovacion === 99
                      ? NO_MORE_FEES
                      : paymentLink.AniosRenovacion
                  }}
                  {{
                    paymentLink.AniosRenovacion === 99 ? "" : i18n.years[iso]
                  }}</b
                >
              </p>
              <p>
                {{ i18n.expiredAt[iso] }}:
                <b>{{ parseDate(paymentLink.DatosUtiles.FechaExpiracion) }}</b>
              </p>
            </div>
            <div class="column is-4">
              <h2>{{ i18n.contract[iso] }}</h2>
              <p>
                {{ i18n.number[iso] }}: <b>{{ paymentLink.contract.Numero }}</b>
              </p>
              <p>
                LoginID: <b>{{ paymentLink.contract.LoginID }}</b>
              </p>
            </div>
          </div>

          <table>
            <tbody>
              <tr>
                <td style="border-width: 0 0 0">{{ i18n.price[iso] }}</td>
                <td style="border-width: 0 0 0; text-align: right">
                  {{ paymentLink.DatosUtiles.priceOriginal }} USD
                </td>
              </tr>
              <tr
                v-if="
                  paymentLink.DatosUtiles &&
                  paymentLink.DatosUtiles.discountYears
                "
              >
                <td style="border-width: 0 0 0">
                  {{ i18n.discount[iso] }} (<b
                    >{{
                      paymentLink.AniosRenovacion === 99
                        ? NO_MORE_FEES
                        : paymentLink.AniosRenovacion
                    }}
                    {{
                      paymentLink.AniosRenovacion === 99 ? "" : i18n.years[iso]
                    }}</b
                  >)
                </td>
                <td style="border-width: 0 0 0; text-align: right">
                  -{{ paymentLink.DatosUtiles.discountYears }} USD
                </td>
              </tr>
              <tr
                v-if="
                  paymentLink.DatosUtiles.discountCupon 
                "
              >
                <td style="border-width: 0 0 0">
                  {{ i18n.discount[iso] }} (<b>{{
                    paymentLink.DatosUtiles.codeCupon
                  }}</b
                  >)
                </td>
                <td style="border-width: 0 0 0; text-align: right">
                  -{{ paymentLink.DatosUtiles.discountCupon }} USD
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style="border-width: 0 0 0"><b>Total</b></td>
                <td style="border-width: 0 0 0; text-align: right">
                  <b>$ {{ paymentLink.Monto }} USD</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-6" style="padding: 10%">
        <div class="center_">
          <b-icon
            pack="fas"
            type="is-success"
            icon="check-circle"
            size="is-small"
            style="font-size: 1000%; margin-left: 50%; margin-top: 5%"
          >
          </b-icon>
          <p style="font-weight: 600; font-size: 2em; margin-top: 6%">
            {{ i18n.paymentLink__paid[iso] }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="columns is-multiline"
      v-else-if="siteShow === 'canceled' || siteShow === 'expired'"
    >
      <div class="column is-6" style="padding: 4%">
        <div class="content bordess" style="padding: 4%">
          <div class="columns">
            <div class="column is-8">
              <h2>Plan</h2>
              <p>
                {{ i18n.duration[iso] }}:
                <b
                  >{{
                    paymentLink.AniosRenovacion === 99
                      ? NO_MORE_FEES
                      : paymentLink.AniosRenovacion
                  }}
                  {{
                    paymentLink.AniosRenovacion === 99 ? "" : i18n.years[iso]
                  }}</b
                >
              </p>
              <p>
                {{ i18n.expiredAt[iso] }}:
                <b>{{ parseDate(paymentLink.DatosUtiles.FechaExpiracion) }}</b>
              </p>
            </div>
            <div class="column is-4">
              <h2>{{ i18n.contract[iso] }}</h2>
              <p>
                {{ i18n.number[iso] }}: <b>{{ paymentLink.contract.Numero }}</b>
              </p>
              <p>
                LoginID: <b>{{ paymentLink.contract.LoginID }}</b>
              </p>
            </div>
          </div>

          <table>
            <tbody>
              <tr>
                <td style="border-width: 0 0 0">{{ i18n.price[iso] }}</td>
                <td style="border-width: 0 0 0; text-align: right">
                  {{ paymentLink.DatosUtiles.priceOriginal }} USD
                </td>
              </tr>
              <tr
                v-if="
                  paymentLink.DatosUtiles &&
                  paymentLink.DatosUtiles.discountYears
                "
              >
                <td style="border-width: 0 0 0">
                  {{ i18n.discount[iso] }} (<b
                    >{{
                      paymentLink.AniosRenovacion === 99
                        ? NO_MORE_FEES
                        : paymentLink.AniosRenovacion
                    }}
                    {{
                      paymentLink.AniosRenovacion === 99 ? "" : i18n.years[iso]
                    }}</b
                  >)
                </td>
                <td style="border-width: 0 0 0; text-align: right">
                  -{{ paymentLink.DatosUtiles.discountYears }} USD
                </td>
              </tr>
              <tr
                v-if="
                  paymentLink.DatosUtiles.discountCupon
                "
              >
                <td style="border-width: 0 0 0">
                  {{ i18n.discount[iso] }} (<b>{{
                    paymentLink.DatosUtiles.codeCupon
                  }}</b
                  >)
                </td>
                <td style="border-width: 0 0 0; text-align: right">
                  -{{ paymentLink.DatosUtiles.discountCupon }} USD
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style="border-width: 0 0 0"><b>Total</b></td>
                <td style="border-width: 0 0 0; text-align: right">
                  <b>$ {{ paymentLink.Monto }} USD</b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column is-6" style="padding: 10%">
        <div class="center_">
          <b-icon
            pack="fas"
            type="is-danger"
            icon="times"
            size="is-small"
            style="font-size: 1000%; margin-left: 50%; margin-top: 5%"
          >
          </b-icon>
          <p style="font-weight: 600; font-size: 2em; margin-top: 6%">
            {{ i18n.paymentLink__canceled[iso] }}
          </p>
        </div>
      </div>
    </div>
    <div v-else></div>
  </div>
</template>

<script>
const NO_MORE_FEES = "No more fees";
const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY_RENEWAL);
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n, { amount, currency } from "../../utils/i18n";

export default {
  name: "LandingPaymentRenewal",
  data() {
    return {
      checkout: null,
      siteShow: "pay",
      paymentLink: null,
      i18n,
      iso: "en",
      NO_MORE_FEES,
    };
  },

  async created() {
    // await this.getPaymentLink();

    this.iso = localStorage.getItem("iso");
    // this.$bus.on("change-iso", () => {
    //   this.iso = localStorage.getItem("iso");
    // });
    if (!this.iso) this.iso = "en";
  },

  async mounted() {
    await this.getPaymentLink();
  },

  methods: {
    changeIso() {
      this.$buefy.dialog.confirm({
        message: this.i18n.changeIso[this.iso],
        type: "is-info",
        onConfirm: () => {
          if (this.iso === "es") {
            localStorage.setItem("iso", "en");
            // this.iso = "en";
          } else {
            localStorage.setItem("iso", "es");
            // this.iso = "es";
          }
          location.reload();
          // this.$forceUpdate();
          // this.$buefy.toast.open("User confirmed")
          // this.$bus.emit("change-iso");
        },
      });
    },
    parseDate(value) {
      moment.locale(this.iso);
      return value ? moment(value).utc().format("MMMM D, YYYY") : "";
    },
    async getPaymentLink() {
      try {
        // this.loading = true;
        const { data } = await axios.get(
          `${API_URL}/renewals/${this.$route.query?.id}`
        );
        console.log("data", data);
        if (!data || !data?.Estado) this.siteShow = "error";
        else if (data?.Estado === "paid") {
          this.paymentLink = data;
          this.siteShow = "paid";
        } else if (data?.Estado === "expired" || data?.Estado === "canceled") {
          this.paymentLink = data;
          this.siteShow = "expired";
        } else {
          this.paymentLink = data;
          // this.iso = data?.Idioma || 'en'
          this.initializeCheckout();
        }

        if (this.$route.query?.forceCancel === "y") this.siteShow = "expired";

        // this.loading = false;
      } catch (err) {
        // this.loading = false;
        this.siteShow = "error";
        console.log(err);
        // this.$buefy.toast.open({
        //   message: "Error al consultar datos de socio",
        //   type: "is-danger",
        // });
      }
    },
    async initializeCheckout() {
      const fetchClientSecret = async () => {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/payment/create/renewal-checkout?payLinkId=${this.$route.query?.id}&iso=${this.iso}`,
          {
            method: "GET",
          }
        );
        const { clientSecret } = await response.json();
        console.log("clientSecret", clientSecret);
        return clientSecret;
      };

      const checkout = await stripe.initEmbeddedCheckout({
        fetchClientSecret,
      });

      this.checkout = checkout;

      // Mount Checkout
      checkout.mount("#checkout");
    },
    appliedDiscount() {
      if (
        this.paymentLink?.DatosUtiles?.cupon &&
        this.paymentLink?.DatosUtiles?.cupon?.discount
      ) {
        return this.paymentLink?.DatosUtiles?.cupon?.discount;
      }
      return 0;
    },
  },
};
</script>

<style scoped>
.withoutBorder {
  border-width: 0 0 0;
}
.bordess {
  border-radius: 13px 13px 13px 13px;
  -webkit-border-radius: 13px 13px 13px 13px;
  -moz-border-radius: 13px 13px 13px 13px;
  border: 4px solid #c3c3c3;
}
.center_ {
  max-width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.FlexContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: auto;
  flex-direction: row;
}

.allColor {
  background-color: white;
}

.fontMain {
  font-family: Poppins;
}

.colorIcons {
  color: #6d6e78 !important;
}

.FlexContainerC {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  overflow: auto;
  flex-direction: column;
}

.shadowPay {
  box-shadow: -12px 0px 12px -4px rgba(195, 195, 195, 1);
  -webkit-box-shadow: -12px 0px 12px -4px rgba(195, 195, 195, 1);
  -moz-box-shadow: -12px 0px 12px -4px rgba(195, 195, 195, 1);
}

iframe {
  display: block; /* iframes are inline by default */
  height: 100vh; /* Set height to 100% of the viewport height */
  width: 100vw; /* Set width to 100% of the viewport width */
  border: none; /* Remove default border */
  background: lightyellow; /* Just for styling */
}
</style>
