<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.renewals[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <b-tabs v-model="tabFilters" position="is-centered" @input="changeTab">
        <b-tab-item>
          <template #header>
            <b-icon icon="filter" pack="fas" type="is-info"></b-icon>
            <b>{{ i18n.advancedSearch[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-field label-position="on-border" label="LoginID">
                <b-input
                  v-model="advancedFilters.LoginID"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.contract[iso]">
                <b-input
                  v-model="advancedFilters.nroContract"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.name[iso]">
                <b-input
                  v-model="advancedFilters.Nombre"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.lastname[iso]">
                <b-input
                  v-model="advancedFilters.ApellidoPaterno"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field label-position="on-border" :label="i18n.email[iso]">
                <b-input
                  v-model="advancedFilters.Email"
                  type="text"
                  icon-right="times"
                  icon-right-clickable
                  expanded
                >
                </b-input>
              </b-field>
            </div>
            <!-- <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.registrationDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaRegistro"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaRegistro.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaRegistro = []"
                >
                </b-datepicker>
              </b-field>
            </div> -->
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.processingDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaProcesable"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaProcesable.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaProcesable = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.renewalDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaRenovacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  :disabled="validateAdvancedFilters.FechaRenovacion"
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaRenovacion.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaRenovacion = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.cancellationDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaCancelacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  :disabled="validateAdvancedFilters.FechaCancelacion"
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaCancelacion.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaCancelacion = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <!-- <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.activationDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaActivacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  :disabled="validateAdvancedFilters.FechaActivacion"
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaActivacion.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaActivacion = []"
                >
                </b-datepicker>
              </b-field>
            </div> -->
            <div class="column is-3">
              <b-field label-position="on-border" :label="'Club'">
                <b-select v-model="advancedFilters.club" expanded>
                  <option :value="null"></option>
                  <option
                    v-for="club in clubesName"
                    :value="club._id"
                    :key="club._id"
                  >
                    {{ club.Nombre }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <!-- <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.subscriptionStatus[iso]"
              >
                <b-select expanded v-model="advancedFilters.status">
                  <option :value="null"></option>
                  <option :value="1">
                    {{ i18n.current[iso] }}
                  </option>
                  <option :value="0">
                    {{ i18n.expired[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div> -->
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.membershipStatus[iso]"
              >
                <b-select expanded v-model="advancedFilters.membershipStatus">
                  <option :value="null"></option>
                  <option :value="1">
                    {{ i18n.pendingActivation[iso] }}
                  </option>
                  <option :value="true">
                    {{ i18n.active[iso] }}
                  </option>
                  <option :value="false">
                    {{ i18n.inactive[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.contractStatus[iso]"
              >
                <b-select
                  expanded
                  v-model="advancedFilters.contractStatus"
                  @input="checkValidateAdvancedFilters"
                >
                  <option :value="null"></option>
                  <option :value="1">
                    {{ i18n.pending[iso] }}
                  </option>
                  <option :value="2">
                    {{ i18n.procesable[iso] }}
                  </option>
                  <option :value="3">
                    {{ i18n.cancelled[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.languageUser[iso]"
              >
                <b-select expanded v-model="advancedFilters.language">
                  <option :value="null"></option>
                  <option :value="'SP'">
                    {{ i18n.spanish[iso] }}
                  </option>
                  <option :value="'EN'">
                    {{ i18n.english[iso] }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <!-- <div class="column is-2"></div> -->
            <!-- <div class="column is-2">
                <b-switch
                  :rounded="false"
                  size="is-medium"
                  v-model="advancedFilters.onlyHeadlines"
                  :true-value="true"
                  :false-value="false"
                  type="is-success"
                >
                  {{ i18n.onlyHeadlines[iso] }}
                </b-switch>
              </div> -->
            <div class="column is-2">
              <b-button
                icon-left="search"
                icon-pack="fas"
                type="is-info"
                @click="advancedSearch(false)"
                expanded
                >{{ i18n.search[iso] }}</b-button
              >
            </div>

            <div class="column is-1">
              <b-button
                icon-left="eraser"
                icon-pack="fas"
                type="is-danger"
                @click="clearAdvancedSearch"
                expanded
              ></b-button>
            </div>

            <div
              class="column is-1"
              v-if="$checkPermission('export-list-members')"
            >
              <b-button
                icon-left="file-excel"
                icon-pack="fas"
                type="is-primary"
                @click="advancedSearch(true)"
                expanded
                :loading="loadingExportMembers"
              ></b-button>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <b-tabs v-model="tabFilters" position="is-centered" @input="changeTab">
        <b-tab-item>
          <template #header>
            <b-icon icon="lightbulb" pack="fas" type="is-dark"></b-icon>
            <b>{{ i18n.actions[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-2">
              <b-button
                icon-left="paper-plane"
                icon-pack="fas"
                type="is-success"
                @click="advancedSearch(false)"
                expanded
                >{{ i18n.sendLinkPayment[iso] }}</b-button
              >
            </div>
          </div>
          <hr />
          <div class="columns is-multiline">
            <div class="column is-2">
              <b-field label-position="on-border" :label="i18n.agent[iso]">
                <b-select expanded v-model="assignAgent">
                  <option :value="null">{{ i18n.selectAgent[iso] }}</option>
                  <option :value="agent.Usuario" v-for="agent in agents">
                    {{ agent.Nombre }} {{ agent.Apellido }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column is-2">
              <b-button
                icon-left="user-plus"
                icon-pack="fas"
                type="is-info"
                @click="advancedSearch(false)"
                expanded
                :disabled="assignAgent === null"
                >{{ i18n.renewalAssign[iso] }}</b-button
              >
            </div>

            <div class="column is-2">
              <b-button
                icon-left="user-minus"
                icon-pack="fas"
                type="is-danger"
                @click="clearAdvancedSearch"
                expanded
                :disabled="checkedMembers.length === 0"
                >{{ i18n.renewalAssignRemove[iso] }}</b-button
              >
            </div>

            <!-- <div class="column is-2">
              <b-tag size="is-large"
                >{{ i18n.totalSelected[iso] }}:
                {{ checkedMembers.length }}</b-tag
              >
            </div> -->
          </div>
        </b-tab-item>
      </b-tabs>
      <hr />
      <b-tag size="is-large"
        >{{ i18n.totalSelected[iso] }}: {{ checkedMembers.length }}</b-tag
      >
      <b-table
        :data="members"
        :paginated="isPaginated"
        backend-pagination
        :total="total"
        :per-page="perPage"
        @page-change="onPageChange"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :bordered="isBordered"
        :striped="true"
        :narrowed="isNarrowed"
        :hoverable="true"
        :loading="loadingMembers"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
        checkable
        checkbox-type="is-info"
        :checked-rows.sync="checkedMembers"
      >
        <b-table-column
          field="Numero"
          :label="i18n.contract[iso]"
          centered
          width="250"
          v-slot="props"
        >
          <div>
            {{ (props.row.ContratoId && props.row.ContratoId.Numero) || "n/a" }}
          </div>
        </b-table-column>

        <b-table-column
          field="user"
          label="LoginID"
          width="250"
          centered
          v-slot="props"
        >
          <div
            v-html="
              formatTextBySearch(
                props.row.ContratoId && props.row.ContratoId.LoginID,
                'LoginID'
              ) || `n/a`
            "
          ></div>
        </b-table-column>

        <b-table-column
          field="Nombre"
          :label="i18n.name[iso]"
          width="250"
          centered
          v-slot="props"
        >
          <div
            v-html="
              `${formatTextBySearch(props.row.Nombre, 'Nombre') || 'n/a'} ${
                formatTextBySearch(
                  props.row.ApellidoPaterno,
                  'ApellidoPaterno'
                ) || 'n/a'
              }`
            "
          ></div>
        </b-table-column>

        <b-table-column
          field="club"
          label="Club"
          width="250"
          centered
          v-slot="props"
        >
          <div>
            {{
              (props.row.ContratoId &&
                props.row.ContratoId.ClubId &&
                props.row.ContratoId.ClubId.Nombre) ||
              "n/a"
            }}
          </div>
        </b-table-column>

        <b-table-column
          field="expiredAt"
          :label="i18n.renewalDate[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <div>
            <b-tag
              :type="
                !props.row.Estado ? `is-danger is-light` : 'is-success is-light'
              "
              ><b>{{
                formatDate(
                  props.row.ContratoId && props.row.ContratoId.FechaRenovacion
                ) || "n/a"
              }}</b></b-tag
            >
          </div>
        </b-table-column>

        <b-table-column
          field="Numero"
          :label="i18n.assigned[iso]"
          centered
          width="150"
          v-slot="props"
        >
          Jota
        </b-table-column>
        <!-- 
        <b-table-column
          field="status"
          :label="i18n.status[iso]"
          width="100"
          centered
          v-slot="props"
        >
          <b-tooltip
            :type="
              checkSubscription(props.row.ContratoId) &&
              checkMembership(props.row.ContratoId) === 3
                ? 'is-dark'
                : checkSubscription(props.row.ContratoId) &&
                  checkMembership(props.row.ContratoId) !== 3
                ? 'is-success'
                : 'is-danger'
            "
          >
            <b-icon
              icon="user"
              pack="fas"
              size="is-small"
              :type="
                checkSubscription(props.row.ContratoId) &&
                checkMembership(props.row.ContratoId) === 3
                  ? 'is-dark'
                  : checkSubscription(props.row.ContratoId) &&
                    checkMembership(props.row.ContratoId) !== 3
                  ? 'is-success'
                  : 'is-danger'
              "
              style="margin-right: 10px"
            >
            </b-icon>
            <template v-slot:content>
              <b
                >{{ i18n.subscription[iso] }}:
                {{
                  checkSubscription(props.row.ContratoId) &&
                  checkMembership(props.row.ContratoId) === 3
                    ? i18n.pending[iso]
                    : checkSubscription(props.row.ContratoId) &&
                      checkMembership(props.row.ContratoId) !== 3
                    ? i18n.current[iso]
                    : i18n.expired[iso]
                }}</b
              ></template
            >
          </b-tooltip>

          <b-tooltip
            :type="
              checkMembership(props.row.ContratoId) === 1
                ? 'is-success'
                : checkMembership(props.row.ContratoId) === 2
                ? 'is-danger'
                : 'is-dark'
            "
          >
            <b-icon
              icon="star"
              pack="fas"
              size="is-small"
              :type="
                checkMembership(props.row.ContratoId) === 1
                  ? 'is-success'
                  : checkMembership(props.row.ContratoId) === 2
                  ? 'is-danger'
                  : 'is-dark'
              "
              style="margin-right: 10px"
            >
            </b-icon>
            <template v-slot:content>
              <b
                >{{ i18n.membership[iso] }}:
                {{
                  checkMembership(props.row.ContratoId) === 1
                    ? i18n.active[iso]
                    : checkMembership(props.row.ContratoId) === 2
                    ? i18n.inactive[iso]
                    : i18n.pendingActivation[iso]
                }}</b
              ></template
            >
          </b-tooltip>

          <b-tooltip
            :type="
              props.row.ContratoId.EstadoContrato === 1
                ? 'is-info'
                : props.row.ContratoId.EstadoContrato === 2
                ? 'is-success'
                : 'is-danger'
            "
          >
            <b-icon
              icon="file-contract"
              pack="fas"
              size="is-small"
              :type="
                props.row.ContratoId.EstadoContrato === 1
                  ? 'is-info'
                  : props.row.ContratoId.EstadoContrato === 2
                  ? 'is-success'
                  : 'is-danger'
              "
              style="margin-right: 10px"
            >
            </b-icon>
            <template v-slot:content>
              <b
                >{{ i18n.contract[iso] }}:
                {{
                  props.row.ContratoId.EstadoContrato === 1
                    ? i18n.pending[iso]
                    : props.row.ContratoId.EstadoContrato === 2
                    ? i18n.procesable[iso]
                    : i18n.cancelled[iso]
                }}</b
              ></template
            >
          </b-tooltip>
        </b-table-column> -->

        <!-- <b-table-column
          label="Actions"
          v-slot="props"
          centered
          width="170"
          v-if="$checkTotalPermissionsAvailable(['show-profile-members'])"
        >
          <b-tooltip
            type="is-success is-light"
            v-if="$checkPermission('show-profile-members')"
          >
            <b-button
              icon-left="eye"
              icon-pack="fas"
              size="is-small"
              type="is-success is-light"
              @click="goPath(`/membership-profile?id=${props.row._id}`)"
              style="margin-right: 10px"
            ></b-button>
            <template v-slot:content>
              <b>{{ i18n.viewContract[iso] }}</b></template
            >
          </b-tooltip>
        </b-table-column> -->

        <template #footer>
          <div class="columns">
            <div class="column is-11"></div>

            <div class="column is-1">
              <b-field :label="i18n.quantity[iso]">
                <b-select v-model="perPage" @input="savePerPage" expanded>
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </template>
      </b-table>
    </section>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";

const today = new Date();

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      total: 0,
      expandedRow: null,
      loadingExportMembers: false,

      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      //Filters
      tabFilters: 0,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      clubesName: [],
      loadingClubes: false,
      itemsFilters: [],
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        FechaActivacion: [],
        club: null,
        status: 0,
        onlyHeadlines: false,
        language: null,
        contractStatus: null,
        membershipStatus: null,
      },
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        EstadoMembresia: false,
        EstadoContrato: false,
        FechaActivacion: false,
      },

      /**
       * Members
       */

      members: [],
      loadingMembers: false,
      checkedMembers: [],

      /**
       * Modal
       */

      isActiveModalCommentMember: false,
      memberIdModalCommentMember: null,

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),

      /**
       * Asignaciones
       */
      assignAgent: null,
      agents: [],
      loadingAgents: false,
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //Tabs
    const tabFilters = localStorage.getItem("tabFilters");
    if (tabFilters === 1 || tabFilters === "1") this.tabFilters = 1;

    //Members
    this.listAllClubes();
    let stateMembers = localStorage.getItem("members");
    stateMembers = JSON.parse(stateMembers);
    console.log("stateMembers", stateMembers);
    if (
      stateMembers &&
      stateMembers?.exp &&
      stateMembers.exp > moment().valueOf() &&
      stateMembers.lastSearchMembers &&
      stateMembers.lastSearchMembers !== "null"
    ) {
      this.searchMembers = stateMembers.lastSearchMembers;
      this.passValidateSearch = true;
      this.listAllMembers({}, true, stateMembers);
    } else this.listAllMembers(this.advancedFilters);
  },

  components: {},
  methods: {
    async listAllMembers(filters = {}, isState = false, state = {}) {
      try {
        console.log("isState", isState);
        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            {
              ...filters,
              FechaProcesableExiste:
                filters.contractStatus === 2 ? true : undefined,
            }
          );
          console.log("members", data);
          this.members = data?.members || [];
          this.total = data.paginate.total;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.loadingMembers = false;

          /**
           * State
           */
          const stateMembers = {
            data: this.members,
            exp: moment().add(20, "minute").valueOf(),
            total: this.total,
            lastSearchMembers: this.lastSearchMembers,
            itemsFilters: this.itemsFilters,
          };
          localStorage.setItem("members", JSON.stringify(stateMembers));
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    savePerPage() {
      localStorage.setItem("perPageReserves", this.perPage);
      this.listAllMembers();
    },
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },

    clearInputSearch() {
      this.searchMembers = null;
      this.passValidateSearch = false;
      this.listAllMembers();
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    changeTab() {
      localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre",
        });
        this.clubesName = data?.clubes || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      if (isExport) {
        this.exportAllMembers(this.advancedFilters);
      } else {
        this.currentPage = 1;
        this.listAllMembers(this.advancedFilters);
      }
    },
    basicSearch(isExport = false) {
      if (isExport) {
        if (this.searchMembers && this.searchMembers !== "")
          this.exportAllMembers({
            rgx: this.searchMembers,
          });
        else this.exportAllMembers({});
      } else {
        this.currentPage = 1;
        if (this.searchMembers && this.searchMembers !== "")
          this.listAllMembers({
            rgx: this.searchMembers,
          });
      }
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    onPageChange(page) {
      this.currentPage = page;
      // console.log("checkAdvancedSearch()", checkAdvancedSearch());
      if (this.searchMembers && this.searchMembers !== "") {
        this.listAllMembers({
          rgx: this.searchMembers,
        });
      } else if (this.checkAdvancedSearch(false)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.listAllMembers();
      }
    },
    clearAdvancedSearch() {
      this.advancedFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaCancelado: [],
        FechaRenovacion: [],
        club: null,
        status: null,
        onlyHeadlines: null,
        language: null,
        contractStatus: null,
        membershipStatus: null,
      };
      this.listAllMembers();
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data.ContratoId?.Idioma,
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner && data?.coOwner?.Email)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.ContratoId?.Idioma,
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("MMM/DD/YYYY").toUpperCase();
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      console.log("result", result);
      return result.filter((b) => b && b !== "");
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExportMembers = true;
        let query = `?export=s`;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/list-all${query}`,
          filters
        );
        console.log("members", data);

        const blob = new Blob([data.members.csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "members.csv");
        a.click();
        this.loadingExportMembers = false;
      } catch (err) {
        this.loadingExportMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
    toggleRow(row) {
      row.isExpanded = !row.isExpanded;
    },
    checkSubscription(contract) {
      const now = Date.now();
      if (
        contract &&
        contract?.FechaRenovacion &&
        contract?.FechaRenovacion < now
      )
        return false;
      else return true;
    },
    checkSubscriptionn(contract) {
      const now = Date.now();
      let tmp = true;
      if (
        contract &&
        contract?.FechaRenovacion &&
        contract?.FechaRenovacion < now
      )
        return false;

      if (tmp && this.checkMembership() === 3) tmp = 1;
    },
    checkMembership(contract) {
      if (contract && contract?.EstadoMembresia === true) return 1;
      else if (contract && contract?.EstadoMembresia === false) return 2;
      else return 3;
    },
  },
};
</script>

<style scoped>
.pagination .is-simple .info {
  display: none;
}
</style>
