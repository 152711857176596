var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card"},[_vm._m(0),_c('div',{staticClass:"card-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":"Login ID *","label-position":_vm.labelPosition,"type":!_vm.loginIdApply ? 'is-danger' : 'is-success',"message":!_vm.loginIdApply
                ? _vm.i18n.applyLoginId__fail[_vm.iso]
                : ((_vm.i18n.applyLoginId__ok[_vm.iso]) + " | " + _vm.loginIdApply)}},[_c('b-input',{attrs:{"type":"search","expanded":""},model:{value:(_vm.loginId),callback:function ($$v) {_vm.loginId=$$v},expression:"loginId"}}),_c('p',{staticClass:"control"},[_c('b-button',{staticClass:"button is-info",attrs:{"disabled":!_vm.loginId,"loading":_vm.findLoginIdLoading},on:{"click":_vm.findMembersByLoginId}},[_vm._v(_vm._s(_vm.i18n.apply[_vm.iso]))])],1)],1)],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"label":_vm.i18n.traveler[_vm.iso],"label-position":_vm.labelPosition}},[_c('b-select',{attrs:{"expanded":""},on:{"input":_vm.applyTraveler},model:{value:(_vm.traveler),callback:function ($$v) {_vm.traveler=$$v},expression:"traveler"}},[_vm._l((_vm.listOwners),function(item){return _c('option',{domProps:{"value":item.socio}},[_vm._v(" "+_vm._s(item.name)+" ")])}),_c('option',{attrs:{"value":"o"}},[_vm._v(" "+_vm._s(_vm.i18n.other[_vm.iso])+" ")])],2)],1)],1),_c('div',{staticClass:"column is-3"},[_c('b-field',{attrs:{"label":_vm.i18n.name[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.name ? 'is-danger' : '',"message":!_vm.passInfo ? 'Este campo es requerido' : ''}},[_c('b-input',{attrs:{"maxlength":"30"},on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.name),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "name", $$v)},expression:"travelerInfo.name"}})],1)],1),_c('div',{staticClass:"column is-3"},[_c('b-field',{attrs:{"label":_vm.i18n.lastname[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.lastname ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.lastname
                ? 'Este campo es requerido'
                : ''}},[_c('b-input',{attrs:{"maxlength":"30"},on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.lastname),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "lastname", $$v)},expression:"travelerInfo.lastname"}})],1)],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"label":_vm.i18n.cellphone[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.phone ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.phone
                ? 'Este campo es requerido'
                : ''}},[_c('b-input',{attrs:{"maxlength":"30","type":"number"},on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.phone),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "phone", $$v)},expression:"travelerInfo.phone"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":_vm.i18n.email[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.email ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.email
                ? 'Este campo es requerido'
                : ''}},[_c('b-input',{attrs:{"type":"email"},on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.email),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "email", $$v)},expression:"travelerInfo.email"}})],1)],1),_c('div',{staticClass:"column is-4"},[_c('b-field',{attrs:{"label":_vm.i18n.email[_vm.iso] + ' 2',"label-position":_vm.labelPosition}},[_c('b-input',{attrs:{"type":"email"},on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.email2),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "email2", $$v)},expression:"travelerInfo.email2"}})],1)],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"label":_vm.i18n.language[_vm.iso],"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.iso ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.iso ? 'Este campo es requerido' : ''}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.travelerInfo.iso),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "iso", $$v)},expression:"travelerInfo.iso"}},[_c('option',{attrs:{"value":"es"}},[_vm._v(" "+_vm._s(_vm.i18n.spanish[_vm.iso])+" ")]),_c('option',{attrs:{"value":"en"}},[_vm._v(" "+_vm._s(_vm.i18n.english[_vm.iso])+" ")])])],1)],1),_c('div',{staticClass:"column is-3"},[_c('b-field',{attrs:{"label":_vm.i18n.country[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.country ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.country
                ? 'Este campo es requerido'
                : ''}},[_c('b-select',{attrs:{"expanded":""},on:{"input":_vm.listStates},model:{value:(_vm.travelerInfo.country),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "country", $$v)},expression:"travelerInfo.country"}},_vm._l((_vm.countries),function(item,idx){return _c('option',{key:idx,domProps:{"value":item.Country}},[_vm._v(" "+_vm._s(item.Country)+" ")])}),0)],1)],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"label":_vm.i18n.state[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.state ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.state
                ? 'Este campo es requerido'
                : ''}},[_c('b-select',{attrs:{"expanded":"","disabled":_vm.states.length === 0},model:{value:(_vm.travelerInfo.state),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "state", $$v)},expression:"travelerInfo.state"}},_vm._l((_vm.states),function(item,idx){return _c('option',{key:idx,domProps:{"value":item.CODIGO}},[_vm._v(" "+_vm._s(item.NOMBRE_ESTADO)+" ")])}),0)],1)],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"label":_vm.i18n.city[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.city ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.city ? 'Este campo es requerido' : ''}},[_c('b-input',{on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.city),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "city", $$v)},expression:"travelerInfo.city"}})],1)],1),_c('div',{staticClass:"column is-3"},[_c('b-field',{attrs:{"label":_vm.i18n.address[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.address ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.address
                ? 'Este campo es requerido'
                : ''}},[_c('b-input',{on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.address),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "address", $$v)},expression:"travelerInfo.address"}})],1)],1),_c('div',{staticClass:"column is-2"},[_c('b-field',{attrs:{"label":_vm.i18n.zip[_vm.iso] + ' *',"label-position":_vm.labelPosition,"type":!_vm.passInfo && !_vm.travelerInfo.zipcode ? 'is-danger' : '',"message":!_vm.passInfo && !_vm.travelerInfo.zipcode
                ? 'Este campo es requerido'
                : ''}},[_c('b-input',{on:{"blur":_vm.mayus},model:{value:(_vm.travelerInfo.zipcode),callback:function ($$v) {_vm.$set(_vm.travelerInfo, "zipcode", $$v)},expression:"travelerInfo.zipcode"}})],1)],1)])])]),_c('footer',{staticClass:"card-footer"},[_c('a',{staticClass:"card-footer-item button is-danger is-outlined is-small",staticStyle:{"height":"auto"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('b',[_vm._v("CANCELAR")])]),_c('a',{class:("card-footer-item button is-success is-outlined is-small " + (_vm.loading ? 'is-loading' : '')),staticStyle:{"height":"auto"},on:{"click":function($event){_vm.mode === 'hold' ? _vm.holdFull() : _vm.addTraveler()}}},[_c('b',[_vm._v(_vm._s(_vm.mode === "hold" ? "HOLD" : "AGREGAR VIAJERO"))])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Datos del viajero")])])}]

export { render, staticRenderFns }