/**
 * Este archivo es una guia de los permisos que existen en el backoffice
 */

const permissions = [
  /**
   * Modulo de semanas
   */
  {
    Nombre: "Semanas",
    NombreEn: "Weeks",
    pathMain: "/week",
    items: [
      {
        module: "list-weeks",
        path: "/week",
        type: "path",
        main: "weeks",
      },
      {
        module: "hold-week",
        path: null,
        type: "action",
        parent: "weeks",
      },
      {
        module: "hold-fast-week",
        path: null,
        type: "action",
        parent: "weeks",
      },
    ],
    mainItemModule: "list-weeks",
  },
  /**
   * Modulo de reservas
   */
  {
    Nombre: "Reservas",
    NombreEn: "Bookings",
    pathMain: "/reserve",
    items: [
      {
        module: "list-bookings",
        path: "/reserve",
        type: "path",
        main: "bookings",
      },
      {
        module: "send-payment-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "get-payments-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "send-confirmation-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "hold-force-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "get-logs-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "hold-release-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "modify-traveler-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "add-traveler-bookings",
        path: null,
        type: "action",
        parent: "bookings",
      },
      {
        module: "force-change-status",
        path: null,
        type: "action",
        parent: "bookings",
      },
      //   {
      //     module: "export-list-bookings",
      //     path: null,
      //     type: "action",
      //     parent: "bookings",
      //   },
    ],
    mainItemModule: "list-bookings",
  },
  /**
   * Modulo de socios
   */
  {
    Nombre: "Socios",
    NombreEn: "Members",
    pathMain: "/catcher-members",
    items: [
      {
        module: "list-members",
        path: "/catcher-members",
        type: "path",
        main: "members",
      },
      {
        module: "export-list-members",
        path: null,
        type: "action",
        parent: "members",
      },
    ],
    mainItemModule: "list-members",
  },
  /**
   * Modulo de perfil socio
   */
  {
    Nombre: "Perfil Socio",
    NombreEn: "Profile Member",
    pathMain: "/catcher-members",
    items: [
      {
        module: "show-profile-members",
        path: null,
        type: "action",
        parent: "members",
        main: "members-profile",
      },
      {
        module: "edit-profile-members",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "change-status-membership",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "change-status-contract",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "change-status-contract__cancel",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "send-email-welcome-membership",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "renew-contract",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "show-comments-contract",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "create-comments-contract",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "edit-contract-webtemporary",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "reset-renewal",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "edit-contract-cap",
        path: null,
        type: "action",
        parent: "members-profile",
      },
      {
        module: "add-co-owner-cap",
        path: null,
        type: "action",
        parent: "members-profile",
      },
    ],
    mainItemModule: "show-profile-members",
  },
  /**
   * Modulo de enlaces de pago
   */
  {
    Nombre: "Enlaces de Pago",
    NombreEn: "Payment links",
    pathMain: "/payments-links",
    items: [
      {
        module: "list-paymentslinks",
        path: "/payments-links",
        type: "path",
        main: "paymentslinks",
      },
      {
        module: "create-paymentslinks",
        path: null,
        type: "action",
        parent: "paymentslinks",
      },
    ],
    mainItemModule: "list-paymentslinks",
  },
  /**
   * Modulo de usuarios de sistema y roles
   */
  {
    Nombre: "Sistema",
    NombreEn: "System",
    pathMain: "/system-users",
    items: [
      {
        module: "list-system-users",
        path: "/system-users",
        type: "path",
        main: "system-users",
      },
      {
        module: "list-system-roles",
        path: "/system-roles",
        type: "path",
        main: "system-roles",
      },
    ],
    mainItemModule: "list-system-users",
  },
  /**
   * Modulo de activaciones
   */
  {
    Nombre: "Activaciones",
    NombreEn: "Activations",
    pathMain: "/activations",
    items: [
      {
        module: "list-members-activations",
        path: "/activations",
        type: "path",
        main: "members-activations",
      },
    ],
    mainItemModule: "list-members-activations",
  },
  /**
   * Modulo de capturador
   */
  {
    Nombre: "Capturador",
    NombreEn: "Capturer",
    pathMain: "/new-member",
    items: [
      {
        module: "capturer",
        path: "/new-member",
        type: "path",
        main: "capturer",
      },
    ],
    mainItemModule: "capturer",
  },
  /**
   * Modulo de renovaciones
   */
  {
    Nombre: "Renovaciones",
    NombreEn: "Renovations",
    pathMain: "/customer-portfolio",
    items: [
      {
        module: "renewals-customer-portfolio",
        path: "/activations",
        type: "path",
        main: "renewals-customer-portfolio",
      },
    ],
    mainItemModule: "renewals-customer-portfolio",
  },
  /**
   * Modulo de Semanas Externo
   */
  {
    Nombre: "Login Semanas",
    NombreEn: "Login Weeks",
    pathMain: null,
    items: [
      {
        module: "login-weeks",
        path: null,
        type: "ref",
        main: "login-weeks",
      },
    ],
    mainItemModule: "login-weeks",
  },
];

const checkPermission = (mod) => {
  const role = localStorage.getItem("role") || null;

  if (!mod) return false;
  if (role === "ultrauser" || role === "admin") return true;

  const allPermissions = localStorage.getItem("permissions");
  if (
    allPermissions &&
    Array.isArray(JSON.parse(allPermissions)) &&
    JSON.parse(allPermissions).includes(mod)
  ) {
    return true;
  }
  return false;
};

const checkTotalPermissionsAvailable = (listMod) => {
  if (!listMod) return 0;

  const role = localStorage.getItem("role") || null;
  if (role === "ultrauser" || role === "admin") return true;

  const allPermissions = localStorage.getItem("permissions");
  if (allPermissions && Array.isArray(JSON.parse(allPermissions))) {
    let count = 0;
    for (let mod of listMod)
      if (JSON.parse(allPermissions).includes(mod)) count++;
    return count;
  }
  return 0;
};

const checkMenuItems = (menuItems) => {
  const role = localStorage.getItem("role") || null;
  const allPermissions = localStorage.getItem("permissions") || [];

  return menuItems.map((m) => {
    if (role === "ultrauser" || role === "admin") m.show = true;
    else if (m.subItems.length > 0) {
      if (allPermissions && Array.isArray(JSON.parse(allPermissions))) {
        m.subItems = m.subItems.filter((ms) =>
          JSON.parse(allPermissions).includes(ms.module)
        );
        if (m.subItems.length === 0) m.show = false;
        else m.show = true;
      } else m.show = false;
    } else if (m.subItems.length === 0 && m.module) {
      if (
        allPermissions &&
        Array.isArray(JSON.parse(allPermissions)) &&
        JSON.parse(allPermissions).includes(m.module)
      ) {
        m.show = true;
      }
    } else m.show = false;
    return {
      ...m,
    };
  });
};

module.exports = {
  checkPermission,
  checkTotalPermissionsAvailable,
  checkMenuItems,
  permissions,
};
