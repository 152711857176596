<template>
  <div id="card" style="height: auto; padding: 20px; scroll-behavior: smooth">
    <article class="media" v-show="comments.length === 0">
      <div class="media-content">
        <div class="content">{{ i18n.noComment[iso] }}</div>
      </div>
    </article>
    <div
      class="scrollbar"
      id="comments-#comments-.card"
      style="max-height: 590px; overflow-y: auto; scroll-behavior: smooth"
    >
      <article class="comment-block" v-for="(c, i) in comments" :key="i">
        <div class="comment-header">
          <div class="comment-header-detail">
            <p>
              <span
                ref="lastcomment"
                id="scr"
                v-if="comments.length - 1 === i"
              ></span>
              <strong>{{
                c.CreadoPor && c.CreadoPor.Nombre
                  ? `${c.CreadoPor.Nombre} ${c.CreadoPor.Apellido}`
                  : c.CreadoPor && !c.CreadoPor.Nombre
                  ? `User: ${c.CreadoPor}`
                  : "system"
              }}</strong>
            </p>
            <div class="info-content">
              <b-tag type="is-info">
                <small>{{
                  (c.Tema && c.Tema.nombre) || i18n.notDefined[iso]
                }}</small>
              </b-tag>
              <div>
                <b-tag type="is-light">
                  <small>{{ formatDate(c.Fecha) }}</small>
                </b-tag>
                <b-tag type="is-light" style="margin-left: 10px">
                  <small>{{ changeMillisToTime(c.Fecha) }}</small>
                </b-tag>
              </div>
            </div>
          </div>
          <p id="comment">{{ c.Comentario }}</p>
        </div>
      </article>
    </div>
    <hr />
    <article class="media">
      <div class="media-content">
        <div class="row">
          <div>
            <b-field
              label-position="on-border"
              :label="i18n.topic[iso]"
              style="margin-top: 10px"
            >
              <b-select
                :disabled="topics.length === 0"
                :placeholder="i18n.topic[iso]"
                v-model="topicSelected"
                expanded
              >
                <option :value="null">-</option>
                <option :value="99">Club</option>
                <option
                  v-for="(item, idx) in topics"
                  :value="item.id_coment_accion"
                  :key="idx"
                >
                  {{ item.nombre }}
                </option>
              </b-select>
            </b-field>
            <b-button
              icon-left="paper-plane"
              icon-pack="fas"
              type="is-success"
              @click="createComment"
              style="width: 220px"
            >
              <!-- {{ i18n.send[iso] }} -->
            </b-button>
          </div>
          <div class="textarea-body">
            <b-input
              rows="4"
              v-model="text"
              maxlength="1000"
              type="textarea"
              :placeholder="i18n.addComment[iso]"
              class="flex-grow-1"
            >
            </b-input>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n from "../../utils/i18n";

export default {
  props: ["ContratoId", "topicSelectedDefault"],
  data() {
    return {
      labelPosition: "on-border",
      isOpen: true,
      logs: [],
      loading: false,
      comments: [],
      text: null,
      user: null,
      topics: [],
      topicSelected: null,
      show: false,

      //
      tabXml: "req",
      lastTabXml: "req",

      //i18n
      i18n,
      iso: null,
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    this.$bus.on("refresh-comments", async () => {
      await this.listComments();
    });

    this.user = localStorage.getItem("user");

    await this.listComments();
    await this.listTopics();

    if (this.topicSelectedDefault)
      this.topicSelected = this.topicSelectedDefault;
  },
  mounted() {},

  methods: {
    async listComments() {
      try {
        this.loading = true;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/comments/list`,
          {
            ContratoId: this.ContratoId,
          }
        );
        this.comments = data.comments;
        this.comments = this.comments
          .map((c) => {
            c.createdAtMs = moment(c.createdAtMs).format("DD/MM/YYYY");
            return {
              ...c,
              createdAtMs: moment(c.Fecha).valueOf(),
            };
          })
          .sort((a, b) => b.createdAtMs - a.createdAtMs);

        this.show = true;
        this.loading = false;
        this.goLastToComment();
      } catch (error) {
        this.loading = false;
        this.show = true;
        console.log(error);
        this.$buefy.toast.open({
          message: "Error al listar comentarios",
          type: "is-danger",
        });
      }
    },
    formatDate(date) {
      moment.locale(this.iso);
      return moment(date).format("DD/MM/YYYY");
    },
    async createComment() {
      try {
        if (!this.topicSelected) {
          this.$buefy.toast.open({
            message: this.i18n.validateMsgTopic[this.iso],
            type: "is-warning",
          });
          return;
        }

        this.loading = true;
        await axios.post(`${API_URL}/catcher/members/comments/create`, {
          Comentario: this.text,
          Tipo: "contract",
          ContratoId: this.ContratoId,
          CreadoPor: localStorage.getItem("user"), //parseInt(localStorage.getItem("userId")),
          Tema: this.topicSelected,
        });

        this.text = null;
        this.topicSelected = null;
        this.listComments();

        this.loading = false;
      } catch (error) {
        this.loading = false;
        console.log(error);
        this.$buefy.toast.open({
          message: "Error al listar comentarios",
          type: "is-danger",
        });
      }
    },
    goLastToComment() {
      // console.log(this.$refs.lastcomment)
      // console.log('lastComment.length > 0', lastComment.length > 0)
      // const lastComment = this.$refs.lastcomment;
      // if (lastComment && lastComment.length > 0)
      // this.$refs.lastcomment[0].scrollIntoView({ behavior: "smooth" });
      // const scr = document.getElementById("scr");
      // console.log("scr", scr);
      // if (scr) scr.scrollIntoView();
      // console.log('this.$refs', this.$refs)
      // console.log('this.$refs', this.$refs.lastcomment[0])
      // this.$refs.lastcomment[0].scrollIntoView({ behavior: "smooth" });
      // var container = this.$el.querySelector("#scr");
      // console.log('container', container)
      // container.scrollTop = container.scrollHeight;
    },
    async listTopics() {
      try {
        // this.loading = true;
        const { data } = await axios.get(`${API_URL}/catalogue/CatTopico`);
        this.topics = data;

        // this.loading = false;
      } catch (error) {
        // this.loading = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al listar tópicos",
          type: "is-danger",
        });
      }
    },

    changeMillisToTime(timeInMillis) {
      return moment(timeInMillis).format("hh:mm");
    },
  },
};
</script>

<style>
.comment-block {
  width: 100%;
  padding: 1rem;
}

.comment-block:not(:last-child) {
  border-bottom: 1px solid rgb(237, 237, 237);
}
.comment-header {
  display: flex;
  flex-direction: row;
  gap: 0.2rem;
  align-items: center;
}

.comment-header-detail {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  margin-bottom: auto;
}

.info-content {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}

#comment {
  background-color: #f4f4f4;
  border: 1px solid #d6d6d6;
  padding: 10px;
  border-radius: 6px;
  margin-top: 20px;
  width: 90%;
}

.subtitle-content {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 10px;
}
.scrollbar {
  --sb-track-color: #ececec;
  --sb-thumb-color: #95989a;
  --sb-size: 10px;
}

.scrollbar::-webkit-scrollbar {
  width: var(--sb-size);
}

.scrollbar::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 5px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 5px;
}

.media-content {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;

  gap: 0.5rem;
}

.textarea-body {
  position: relative;
  width: 100%;
}
.textarea-body textarea {
  margin-top: 10px;
  padding-right: 34px;
  height: 30px;
}
.textarea-body > button {
  position: absolute;
  top: 14px;
  right: 7px;
}
#button-send {
  top: 14px;
  right: 10%;
  transform: translateY(-50%);
}
</style>
