import Vue from "vue";
import VueRouter from "vue-router";
import LandingPaymentRenewal from "@/views/Landings/LandingPaymentRenewal.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "LandingPaymentRenewal",
    component: LandingPaymentRenewal,
    meta: {
      isAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
