<template>
  <div>
    <b-navbar id="navbar-cap" centered>
      <template #brand>
        <img
          :src="club.Logo"
          alt="Logo"
          style="padding: 5%; margin-left: 30%"
          id="logo"
        />
      </template>
      <template #start>
        <b-navbar-item href="/new-member" active>
          {{ i18n.newMember[iso] }}
        </b-navbar-item>
        <b-navbar-item href="/list-members">
          {{ i18n.members[iso] }}
        </b-navbar-item>
        <b-navbar-dropdown :label="`${club ? club.Nombre : ''}`">
          <b-navbar-item
            v-for="c in clubes"
            @click="changeClub(c)"
            :active="club && club.Id === c.Id ? true : false"
          >
            {{ c.Nombre }}
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="div" v-if="media">
          <b-icon
            pack="fas"
            icon="video"
            size="is-small"
            style="padding-right: 1em"
            type="is-dark"
          >
          </b-icon>
          <strong> {{ i18n.reportBug__msg1[iso] }} </strong>
          <b-tooltip
            :label="i18n.reportBug__msg2[iso]"
            position="is-right"
            :animated="true"
            type="is-danger"
            id="recording-screen"
          >
            <a @click="stopRecordingBug">
              <b-icon
                pack="fas"
                icon="stop"
                size="is-medium"
                style="padding-left: 1.2em; cursor: pointer"
                type="is-danger"
                class="pulsate-bck"
              >
              </b-icon>
            </a>
          </b-tooltip>
        </b-navbar-item>
      </template>

      <template #end>
        <!-- <b-navbar-item tag="div">
          <b-tag type=" is-link is-light">v21082024</b-tag>
        </b-navbar-item> -->
        <b-navbar-dropdown
          :label="user"
          :right="true"
          style="padding-right: 8em"
        >
          <b-tooltip
            :label="i18n.changeLenguage__msg1[iso]"
            position="is-left"
            :animated="true"
            type="is-dark"
          >
            <b-navbar-item @click="changeIso">
              <b-icon
                pack="fas"
                icon="globe"
                size="is-small"
                style="padding-right: 1em"
                type="is-dark"
              >
              </b-icon>
              <strong
                >{{ i18n.language[iso] }}:
                {{
                  iso === "es" ? i18n.spanish[iso] : i18n.english[iso]
                }}</strong
              >
            </b-navbar-item>
          </b-tooltip>

          <b-navbar-item @click="checkReportBug">
            <b-icon
              pack="fas"
              icon="bug"
              size="is-small"
              style="padding-right: 1em"
              type="is-info"
            >
            </b-icon>
            <strong> {{ i18n.reportBug[iso] }} </strong>
            <b-tag type="is-light is-primary" size="is-small" style="margin-left: 0.6em" rounded>Beta</b-tag>
          </b-navbar-item>
          <b-navbar-item @click="exit">
            <b-icon
              pack="fas"
              icon="power-off"
              size="is-small"
              style="padding-right: 1em"
              type="is-danger"
            >
            </b-icon>
            <strong> {{ i18n.logout[iso] }} </strong>
          </b-navbar-item>
        </b-navbar-dropdown>
        <!-- <b-navbar-item>
          {{ user }}
        </b-navbar-item> -->
        <!-- <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-primary" @click="changeIso">
              <strong>{{
                iso === "es" ? i18n.spanish[iso] : i18n.english[iso]
              }}</strong>
            </a>
            <a class="button is-light" @click="exit">
              {{ i18n.logout[iso] }}
            </a>
          </div>
        </b-navbar-item> -->
      </template>
    </b-navbar>
    <main class="h-full pb-16" style="background-color: #f6f5f2">
      <div class="container px-6 mx-auto grid">
        <router-view
          style="background: white; padding: 30px; margin: 30px"
        ></router-view>
        <!-- box-shadow: 0px 0px 19px 3px rgba(255, 255, 255, 1);
            -webkit-box-shadow: 0px 0px 19px 3px rgba(255, 255, 255, 1);
            -moz-box-shadow: 0px 0px 19px 3px rgba(255, 255, 255, 1); -->
      </div>
    </main>
    <footer class="footer" style="padding: 2rem 1.5rem 1.5rem">
      <div class="content has-text-centered">
        <p>
          © {{ `${club ? club.Nombre : ""}` }}, 2024.
          {{ i18n.allRightReserved[iso] }}.
        </p>
        <b-tag type=" is-link is-light"
          ><strong>{{ vCap }}</strong></b-tag
        >
      </div>
    </footer>
  </div>
</template>

<script>
import i18n from "../../utils/i18n";
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;

export default {
  data() {
    return {
      iso: "en",
      i18n,
      user: "",
      clubes: [],
      club: null,
      media: null,
      mediarecorder: null,
      vCap: "v20012025",
    };
  },
  async created() {
    let __iso = localStorage.getItem("iso");
    if (!__iso) {
      this.iso = "en";
      localStorage.setItem("iso", "en");
      this.$bus.emit("change-iso");
      this.$forceUpdate();
    } else {
      this.iso = __iso;
    }

    this.user = localStorage.getItem("user") || "";

    // console.log('localStorage.getItem("iso")', localStorage.getItem("iso"))
    // this.iso = localStorage.getItem("iso") || "en";
    // console.log('iso', this.iso)
    // this.$forceUpdate();

    this.getClub();
  },
  methods: {
    insertIcon(img) {
      let link = null;
      link = document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = img;
      document.getElementsByTagName("head")[0].appendChild(link);
    },

    insertName(name) {
      let text = document.createTextNode(name);
      let title = document.createElement("title");
      title.appendChild(text);
      document.getElementsByTagName("head")[0].appendChild(title);
    },
    changeIso() {
      this.$buefy.dialog.confirm({
        message: this.i18n.changeIso[this.iso],
        type: "is-info",
        onConfirm: () => {
          if (localStorage.getItem("iso") === "es") {
            localStorage.setItem("iso", "en");
            this.iso = "en";
          } else {
            localStorage.setItem("iso", "es");
            this.iso = "es";
          }
          // this.$forceUpdate();
          // this.$buefy.toast.open("User confirmed")
          this.$bus.emit("change-iso");
        },
      });
    },
    exit() {
      localStorage.removeItem("user");
      localStorage.removeItem("role");
      localStorage.removeItem("userEmail");
      localStorage.removeItem("userId");
      localStorage.removeItem("clubes");
      localStorage.removeItem("club");
      localStorage.removeItem("permissions");
      this.$router.push("/logincap");
    },
    getClub() {
      let clubes = localStorage.getItem("clubes");
      clubes = clubes ? JSON.parse(clubes) : [];
      this.clubes = clubes;

      let club = localStorage.getItem("club");
      club = club ? JSON.parse(club) : {};
      this.club = club;

      this.insertIcon(club?.LogoIcon || "");
      this.insertName(club?.Nombre || "");

      this.$forceUpdate();
    },
    changeClub(club) {
      localStorage.setItem("club", JSON.stringify(club));
      this.$bus.emit("change-club");
      this.$router.go(0);
    },
    async reportBug(description) {
      const media = await navigator.mediaDevices.getDisplayMedia({
        video: { frameRate: { ideal: 30 } },
      });
      const mediarecorder = new MediaRecorder(media, {
        mimeType: "video/webm;codecs=vp8,opus",
      });
      mediarecorder.start();

      this.media = media;
      this.mediarecorder = mediarecorder;

      const [video] = media.getVideoTracks();
      video.addEventListener("ended", () => {
        mediarecorder.stop();
        this.media = null;
        this.mediarecorder = null;
      });

      mediarecorder.addEventListener("dataavailable", (e) => {
        // console.log("e.data", e.data);
        const reader = new FileReader();
        reader.readAsDataURL(e.data);
        const __this = this;

        // console.log("reader", reader);

        reader.onload = async function (e) {
          const club = localStorage.getItem("club");
          const user = localStorage.getItem("user");
          try {
            await axios.post(`${API_URL}/bugs/create`, {
              Plataforma: "cap",
              VersionPlataforma: this.vCap,
              ClubId: (club && JSON.parse(club)?.Id) || null,
              Descripcion: description,
              VideoBase64: reader?.result,
              ReportadoPor: user || null,
            });
          } catch (error) {
            console.log(error);
          }
          __this.$buefy.dialog.alert({
            title: __this.i18n.reportBug[__this.iso],
            message: __this.i18n.reportBug__msg6[__this.iso],
            type: "is-success",
            hasIcon: true,
            icon: "bug",
            iconPack: "fas",
            ariaRole: "alertdialog",
            ariaModal: true,
          });
        };

        // const link = document.createElement("a");
        // link.href = URL.createObjectURL(e.data);
        // link.download = "captura.webm";
        // link.click();
      });
    },
    stopRecordingBug() {
      // console.log(this.media);
      // console.log(this.mediarecorder);
      if (this.media && this.mediarecorder) {
        const [video] = this.media.getVideoTracks();
        video.stop();
        this.mediarecorder.stop();
        this.media = null;
        this.mediarecorder = null;
      }
    },
    checkReportBug() {
      this.$buefy.dialog.confirm({
        title: this.i18n.reportBug[this.iso],
        message: this.i18n.reportBug__msg3[this.iso],
        confirmText: this.i18n.iUnderstand[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        type: "is-info",
        icon: "video",
        iconPack: "fas",
        hasIcon: true,
        onConfirm: () => {
          this.$buefy.dialog.prompt({
            message: this.i18n.reportBug__msg4[this.iso],
            inputAttrs: {
              placeholder: this.i18n.reportBug__msg5[this.iso],
              maxlength: 100,
            },
            trapFocus: true,
            onConfirm: (value) => this.reportBug(value),
          });
        },
      });
    },
  },
};
</script>

<style scoped>
#logo {
  @media (width < 1024px) {
    /* width: 20%; */
    display: none;
  }
}
#navbar-cap {
  box-shadow: -1px 7px 8px -3px rgba(0, 0, 0, 0.51);
  -webkit-box-shadow: -1px 7px 8px -3px rgba(0, 0, 0, 0.51);
  -moz-box-shadow: -1px 7px 8px -3px rgba(0, 0, 0, 0.51);
  height: 80px;
}

.pulsate-bck {
  animation: pulsate-bck 1.5s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2024-9-8 14:50:32
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@keyframes pulsate-bck {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

#recording-screen .b-tooltip.is-danger .tooltip-content {
  margin-left: 40px;
}
</style>
