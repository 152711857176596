<template>
  <div class="card" style="width: 480px" id="card-resort">
    <div class="card-image">
      <template v-if="!loading">
        <b-carousel :autoplay="false" :indicator="false">
          <b-carousel-item v-for="(img, i) in week.imgs" :key="i">
            <img :src="img" alt="Resort Image" />
          </b-carousel-item>
        </b-carousel>
      </template>
      <b-skeleton width="480px" height="384px" :active="loading"></b-skeleton>
    </div>
    <div class="card-content">
      <div class="media">
        <div class="media-content">
          <template v-if="!loading">
            <p class="title is-4">{{ week.ResortName }}</p>
            <p class="subtitle is-6">
              {{ resort && resort.Address1
              }}{{
                resort &&
                resort.Address2 &&
                resort.Address2.length > 0 &&
                `, ${resort.Address2}`
              }}{{
                resort &&
                resort.Address3 &&
                resort.Address3.length > 0 &&
                `, ${resort.Address3}`
              }}{{
                resort &&
                resort.Address4 &&
                resort.Address4.length > 0 &&
                `, ${resort.Address4}`
              }}, {{ resort && resort.City }}, {{ resort && resort.State }},
              {{ resort && resort.Country }}, {{ resort && resort.Zip }}
            </p>
            <div class="columns">
              <div
                class="column is-4"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <b-taglist attached style="cursor: pointer">
                  <b-tag
                    @click="openUrlGoogleMap(week.GPS_Lat, week.GPS_Long)"
                    type="is-info is-light"
                    icon="map-marker"
                    icon-pack="fas"
                    >Google Map</b-tag
                  >
                  <!-- <b-tag
                    @click="openUrlGoogleMap(week.GPS_Lat, week.GPS_Long)"
                    type="is-link is-light"
                  >
                    <b-icon pack="fas" icon="external-link-alt" size="is-small">
                    </b-icon
                  ></b-tag> -->
                </b-taglist>
              </div>
              <div
                class="column is-4"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <b-taglist attached style="cursor: pointer">
                  <b-tag
                    @click="showAmenities(resort.amenities)"
                    type="is-link is-light"
                    icon="concierge-bell"
                    icon-pack="fas"
                    >{{ i18n.amenities[iso] }}</b-tag
                  >
                </b-taglist>
              </div>
              <div
                class="column is-4"
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                "
              >
                <b-taglist attached style="cursor: pointer">
                  <b-tag
                    @click="downloadPdfResort()"
                    type="is-success is-light"
                    icon="file-pdf"
                    icon-pack="fas"
                    v-if="!loadingPdf"
                    >PDF</b-tag
                  >
                  <b-tag
                    type="is-success is-light"
                    icon="file-pdf"
                    icon-pack="fas"
                    v-if="loadingPdf"
                    >{{ loadingPdf }}</b-tag
                  >
                </b-taglist>
              </div>
            </div>
          </template>
          <b-skeleton size="is-large" :active="loading" :count="4"></b-skeleton>
        </div>
      </div>

      <div class="content">
        <template v-if="!loading && resort">
          <p v-show="resort"></p>
          {{
            resort && iso === "en" ? resort.HighlightsEN : resort.HighlightsSP
          }}
        </template>
        <b-skeleton size="is-large" :active="loading" :count="6"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import i18n from "../utils/i18n";
import { jsPDF } from "jspdf";
import moment from "moment";

export default {
  name: "VacancyBackofficeModalViewResort",
  props: ["week"],
  data() {
    return {
      resort: null,
      loading: false,
      //i18n
      i18n,
      iso: null,
      loadingPdf: false,
    };
  },

  mounted() {
    this.getResort();
  },

  created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });
  },

  methods: {
    getResort() {
      this.loading = true;
      axios({
        method: "get",
        url: `${API_URL}/resort/${this.week.ResortId}`,
      })
        .then(({ data }) => {
          console.log(data);
          this.resort = data.resort;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.$buefy.toast.open({
            message: "Error al consultar datos",
            type: "is-danger",
          });
        });
    },
    openUrlGoogleMap(lat, lon) {
      // console.log(`https://www.google.com/maps/@${lat},${lon},17z?entry=ttu`);
      window.open(
        `https://www.google.com/maps/@${lat},${lon},18z?entry=ttu`,
        "_blank"
      );
    },
    showAmenities(amenities) {
      // let __html = `<!DOCTYPE html> <html> <body style="width: 600px;"> <div class="columns">`;

      // const numberTmp = 40;
      // const auxDiv = 3;

      // for (let i = numberTmp; i >= 1; i--) {
      //   if (i % 10 === 0 && i === numberTmp)
      //     __html += `<div class="column is-${auxDiv}">`;
      //   if (i % 10 === 0 && i < numberTmp && i > 1) {
      //     __html += `</div> <div class="column is-${auxDiv}">`;
      //   }
      //   if (amenities[numberTmp - i])
      //     __html += `<li>${amenities[numberTmp - i][this.iso]}</li>`;
      // }

      // __html += `</div> </body> </html>`;

      // console.log(__html);

      this.$buefy.dialog.alert({
        title: this.i18n.amenities[this.iso],
        message: amenities.map((a) => a[this.iso]).join(", "),
        confirmText: "OK",
        type: "is-success",
      });
    },

    async downloadPdfResort__DEPRECATED() {
      console.log("week", this.week);

      this.loadingPdf = "Load text...";

      const doc = new jsPDF();

      doc.setFontSize(18);
      doc.text(`${this.resort.ResortName}`, 10, 10);

      doc.setFontSize(12);

      let text = ``;

      for (let i in this.week) {
        if (
          i !== "_id" &&
          i !== "__v" &&
          i !== "ResortName" &&
          i !== "ResortID" &&
          i !== "ResortHighLights" &&
          i !== "ImagePath" &&
          i !== "Images" &&
          i !== "ListofAssets" &&
          i !== "InvTypeInDetail" &&
          i !== "InsertImages" &&
          i !== "imgs" &&
          i !== "GPS_Lat" &&
          i !== "GPS_Long" &&
          i !== "status" &&
          i !== "UnitCount" &&
          i !== "ImportantNotesSP" &&
          i !== "ImportantNotesEN"
        ) {
          text += `${i}: ${this.week[i]} \n\n`;
        }
      }
      const Highlights =
        this.iso === "en" ? this.resort.HighlightsEN : this.resort.HighlightsSP;
      text += `Highlights: ${Highlights} \n\n`;

      const ImportantNotes =
        this.iso === "en"
          ? this.week.ImportantNotesEN
          : this.week.ImportantNotesSP;
      text += `ImportantNotes: ${ImportantNotes} \n\n`;

      for (let i in this.resort) {
        if (
          i === "CheckInTime" ||
          i === "CheckOutTime" ||
          i === "CheckInDate"
        ) {
          text += `${i}: ${this.resort[i]} \n\n`;
        }
      }
      const Amenities = this.resort.amenities
        .map((a) => a[this.iso])
        .join(", ");
      text += `Amenities: ${Amenities} \n\n`;

      const margin = 10;
      const maxWidth = doc.internal.pageSize.width - 2 * margin;
      const lineHeight = 7;
      let y = 30;

      const lines = doc.splitTextToSize(text, maxWidth);

      lines.forEach((line) => {
        if (y > doc.internal.pageSize.height - 10) {
          doc.addPage();
          y = 10;
        }

        doc.text(line, margin, y);
        y += lineHeight;
      });

      const imgsArr = this.week.imgs;
      // https://www.rci.com/static/Resorts/Assets/0512I11X.jpg
      for (let i in imgsArr) {
        try {
          this.loadingPdf = `Load img${parseInt(i) + 1}...`;
          const { data } = await axios.get(
            `${API_URL}/utils/img-to-base64?url=${imgsArr[i]}`
          );
          // console.log(data);
          doc.addPage("a4");
          doc.addImage(data?.base64, "JPG", 10, 10, 180, 180);
        } catch (err) {
          console.log(err);
        }
      }

      doc.save("resort.pdf");

      this.loadingPdf = null;
    },
    async downloadPdfResort__() {
      console.log("week", this.week);

      this.loadingPdf = "Load text...";

      const PoppinsLight = require("@/assets/font/Poppins-Light");
      const PoppinsBold = require("@/assets/font/Poppins-Bold");

      const doc = new jsPDF({
        format: "a3",
        unit: "mm",
        orientation: "portrait",
        putOnlyUsedFonts: true,
      });

      const marginX = 25;

      doc.addFileToVFS(PoppinsLight.file, PoppinsLight.vfs);
      doc.addFont(PoppinsLight.file, PoppinsLight.name, PoppinsLight.style);

      doc.addFileToVFS(PoppinsBold.file, PoppinsBold.vfs);
      doc.addFont(PoppinsBold.file, PoppinsBold.name, PoppinsBold.style);

      console.log(doc.getFontList());

      doc.setTextColor(49, 54, 63);

      doc.setFont(PoppinsLight.name, PoppinsLight.style);
      doc.setFontSize(10);
      doc.text(`SU VIAJE A: `, marginX, 30, {
        charSpace: 1,
      });

      doc.setFont(PoppinsBold.name, PoppinsBold.style);
      doc.setFontSize(24);
      doc.text(`${this.resort.ResortName}`, marginX, 45);

      doc.setDrawColor(4, 114, 170);
      doc.setFillColor(4, 114, 170);
      doc.rect(marginX, 55, 30, 1, "F");

      doc.setFontSize(10);
      doc.setFont(PoppinsLight.name, PoppinsLight.style);
      doc.text(moment().locale(this.iso).format("ll"), 272, 77, {
        align: "right",
      });

      const imgsArr = this.week.imgs;
      let selectedImgs = [];
      let idx = 0;

      while (selectedImgs.length < 4 || idx === imgsArr.length) {
        try {
          this.loadingPdf = `Load img...`;
          const { data } = await axios.get(
            `${API_URL}/utils/img-to-base64?url=${imgsArr[idx]}`
          );
          selectedImgs.push(data);
        } catch (err) {
          console.log(err);
        }
        idx++;
      }

      if (selectedImgs && selectedImgs.length > 0) {
        doc.addImage(selectedImgs[0].base64, "JPG", marginX, 80, 247, 170);
      }

      doc.addPage();

      doc.setFont(PoppinsBold.name, PoppinsBold.style);
      doc.setFontSize(22);
      doc.text(`${this.week.ResortCity}`, marginX, 30);

      doc.setFont(PoppinsLight.name, PoppinsLight.style);
      doc.setFontSize(12);
      doc.text(`${this.week.ResortCountry}`, marginX, 37);

      doc.setDrawColor(4, 114, 170);
      doc.setFillColor(4, 114, 170);
      doc.rect(marginX, 43, 30, 1, "F");

      if (selectedImgs && selectedImgs.length === 4) {
        doc.addImage(selectedImgs[1].base64, "JPG", marginX, 60, 80, 60);
        let newMarginX = marginX + 80 + 3.5;
        doc.addImage(selectedImgs[2].base64, "JPG", newMarginX, 60, 80, 60);
        newMarginX = newMarginX + 80 + 3.5;
        doc.addImage(selectedImgs[3].base64, "JPG", newMarginX, 60, 80, 60);
      }
      if (selectedImgs && selectedImgs.length === 3) {
        doc.addImage(selectedImgs[0].base64, "JPG", marginX, 60, 80, 60);
        let newMarginX = marginX + 80 + 3.5;
        doc.addImage(selectedImgs[1].base64, "JPG", newMarginX, 60, 80, 60);
        newMarginX = newMarginX + 80 + 3.5;
        doc.addImage(selectedImgs[2].base64, "JPG", newMarginX, 60, 80, 60);
      }

      // doc.html('test', newMarginX, 65)

      doc.setFont(PoppinsLight.name, PoppinsLight.style);
      doc.setFontSize(11);
      doc.text(
        `Descripción: \n\n${this.resort.HighlightsSP}\n\n\nNotas Importantes: \n\n${this.week.ImportantNotesSP}`,
        marginX,
        140,
        {
          maxWidth: 247,
        }
      );

      // doc.setFontSize(12);

      let text = ``;

      for (let i in this.week) {
        if (
          i !== "_id" &&
          i !== "__v" &&
          i !== "ResortName" &&
          i !== "ResortID" &&
          i !== "ResortHighLights" &&
          i !== "ImagePath" &&
          i !== "Images" &&
          i !== "ListofAssets" &&
          i !== "InvTypeInDetail" &&
          i !== "InsertImages" &&
          i !== "imgs" &&
          i !== "GPS_Lat" &&
          i !== "GPS_Long" &&
          i !== "status" &&
          i !== "UnitCount" &&
          i !== "ImportantNotesSP" &&
          i !== "ImportantNotesEN"
        ) {
          text += `${i}: ${this.week[i]} \n\n`;
        }
      }
      const Highlights =
        this.iso === "en" ? this.resort.HighlightsEN : this.resort.HighlightsSP;
      text += `Highlights: ${Highlights} \n\n`;

      const ImportantNotes =
        this.iso === "en"
          ? this.week.ImportantNotesEN
          : this.week.ImportantNotesSP;
      text += `ImportantNotes: ${ImportantNotes} \n\n`;

      for (let i in this.resort) {
        if (
          i === "CheckInTime" ||
          i === "CheckOutTime" ||
          i === "CheckInDate"
        ) {
          text += `${i}: ${this.resort[i]} \n\n`;
        }
      }
      const Amenities = this.resort.amenities
        .map((a) => a[this.iso])
        .join(", ");
      text += `Amenities: ${Amenities} \n\n`;

      // const margin = 10;
      // const maxWidth = doc.internal.pageSize.width - 2 * margin;
      // const lineHeight = 7;
      // let y = 30;

      // const lines = doc.splitTextToSize(text, maxWidth);

      // lines.forEach((line) => {
      //   if (y > doc.internal.pageSize.height - 10) {
      //     doc.addPage();
      //     y = 10;
      //   }

      //   doc.text(line, margin, y);
      //   y += lineHeight;
      // });

      // for (let i in imgsArr) {
      //   try {
      //     this.loadingPdf = `Load img${parseInt(i) + 1}...`;
      //     const { data } = await axios.get(
      //       `${API_URL}/utils/img-to-base64?url=${imgsArr[i]}`
      //     );
      //     // console.log(data);
      //     doc.addPage("a4");
      //     doc.addImage(data?.base64, "JPG", 10, 10, 180, 180);
      //   } catch (err) {
      //     console.log(err);
      //   }
      // }

      doc.save("resort.pdf");

      this.loadingPdf = null;
    },

    async downloadPdfResort() {
      console.log("week", this.week);

      let payload = {
        img1: this.week?.imgs[0],
        img2: this.week?.imgs[1],
        img3: this.week?.imgs[2],
        img4: this.week?.imgs[3],
        ResortName: this.week?.ResortName,
        FullAddress: `${this.week?.ResortAddress1 || ""}${
          this.week?.ResortAddress2 ? `, ${this.week?.ResortAddress2}` : ""
        }${
          this.week?.ResortAddress3 ? `, ${this.week?.ResortAddress3}` : ""
        }, ${this.week?.ResortCity}, ${this.week?.ResortCountry}`,
        Cost: this.week?.Cost,
        Amenities:
          (this.resort?.amenities &&
            this.resort?.amenities.map((a) => a[this.iso]).join(",")) ||
          "",
        Description:
          this.iso === "es"
            ? this.resort?.HighlightsSP
            : this.resort?.HighlightsEN,
        CheckIn: this.week?.CheckInDate || "",
        CheckOut: this.week?.CheckOutDate || "",
        ImportantNotes:
          this.iso === "es"
            ? this.week?.ImportantNotesSP
            : this.week?.ImportantNotesEN,
        ContactEmail: "reservations@vacancyrewards.com",
        ContactPhone: "+18772123834",
        Rating: this.resort?.Rating || "0",
        Nights: this.week?.Nights || "7",
        Kitchen: this.week?.KitchenType || "No kitchen",
        Type: this.week?.UnitType || "",
        Ocuppancy: this.week?.MaxOccupancy || "",
        LogoClub:
          "https://booking.vacancyrewards.com/css/clientes/vacancyrewards/images/brand-primary.png",
        FullAddressClub: "2688 SW 137 Avenue. Miami, Florida. 33175 - Miami",
      };

      localStorage.setItem("payloadPdfResort", JSON.stringify(payload));

      // let urlParameters = Object.entries(payload)
      //   .map((e) => e.join("="))
      //   .join("&");

      window.open(`/resort-view-client`);
    },
  },
};
</script>
