<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <p class="title">{{ i18n.onlineRenewals[iso] }}</p>
        <!-- <p class="subtitle">Hero subtitle</p> -->
      </div>
    </section>
    <section style="margin-top: 30px">
      <b-tabs v-model="tabFilters" position="is-centered" @input="changeTab">
        <b-tab-item>
          <template #header>
            <b-icon icon="filter" pack="fas" type="is-info"></b-icon>
            <b>{{ i18n.advancedSearch[iso] }}</b>
          </template>
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-field
                label-position="on-border"
                :label="i18n.renewalDate[iso]"
              >
                <b-datepicker
                  v-model="advancedFilters.FechaRenovacion"
                  placeholder="DD/MM/YYYY"
                  :min-date="minDate"
                  :max-date="maxDate"
                  :locale="iso"
                  range
                  icon="calendar"
                  icon-pack="fas"
                  :icon-right="
                    advancedFilters.FechaRenovacion.length > 0 ? 'times' : ''
                  "
                  icon-right-clickable
                  @icon-right-click="advancedFilters.FechaRenovacion = []"
                >
                </b-datepicker>
              </b-field>
            </div>
            <div class="column is-2">
              <b-button
                icon-left="search"
                icon-pack="fas"
                type="is-info"
                @click="advancedSearch(false)"
                expanded
                >{{ i18n.search[iso] }}</b-button
              >
            </div>
            <!-- <div class="column is-1">
              <b-button
                icon-left="eraser"
                icon-pack="fas"
                type="is-danger is-light"
                @click="clearAdvancedSearch"
                expanded
                ></b-button
              >
            </div> -->
          </div>
          <hr />
          <div class="columns is-multiline">
            <div class="column is-3">
              <b-button
                icon-left="paper-plane"
                icon-pack="fas"
                type="is-success"
                @click="sendAllEmailsRenewals()"
                :disabled="checkedRows.length === 0"
                expanded
                >{{ i18n.sendLinkPaymentSelected[iso] }}</b-button
              >
            </div>
            <div class="column is-3">
              <b-button
                icon-left="paper-plane"
                icon-pack="fas"
                type="is-primary"
                @click="sendAllEmailsRenewalsAll()"
                :disabled="members.length === 0"
                expanded
                >{{ i18n.sendLinkPaymentAll[iso] }}</b-button
              >
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <hr />
      <div class="content">
        <h6>
          {{ i18n.RenewalsSvailableToShip[iso] }}:
          {{ LimiteRenovacionDisponible }}/{{ LimiteRenovacionTotal }}
          <span v-if="LimiteRestableceRenovaciones"
            >| {{ i18n.TheLimitIsResetTo[iso] }}
            {{ formatDateLimitRenewal(LimiteRestableceRenovaciones) }}</span
          >
          <b-icon
            v-if="loadingSettings"
            pack="fas"
            icon="sync-alt"
            type="is-info"
          >
          </b-icon>
          <a @click="listSettigns()"
            ><b-icon
              v-if="!loadingSettings"
              pack="fas"
              icon="sync-alt"
              type="is-info"
              style="cursor: pointer"
            >
            </b-icon
          ></a>
        </h6>
      </div>
      <hr />
      <!-- backend-pagination
      @page-change="onPageChange" 
      @check-all="checkAll" -->
      <b-table
        :data="members"
        :paginated="isPaginated"
        :total="total"
        :per-page="perPage"
        :current-page.sync="currentPage"
        :pagination-simple="isPaginationSimple"
        :pagination-position="paginationPosition"
        :default-sort-direction="defaultSortDirection"
        :pagination-rounded="isPaginationRounded"
        :sort-icon="sortIcon"
        :sort-icon-size="sortIconSize"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        :bordered="isBordered"
        :striped="true"
        :narrowed="isNarrowed"
        :hoverable="true"
        :loading="loadingMembers"
        :focusable="isFocusable"
        :mobile-cards="hasMobileCards"
        checkable
        :checked-rows.sync="checkedRows"
        :is-row-checkable="checkContract"
        :header-checkable="false"
      >
        <template #bottom-left>
          <b>Total checked</b>: {{ checkedRows.length }}
        </template>

        <!-- <b-table-column
          field="status"
          :label="i18n.status[iso]"
          width="50"
          centered
          v-slot="props"
        >
          <b-icon
            v-if="props.row.ContratoId && props.row.ContratoId.EstadoMembresia"
            pack="fas"
            icon="check"
            size="is-small"
            type="is-success"
          >
          </b-icon>
          <b-icon
            v-else
            pack="fas"
            icon="times"
            size="is-small"
            type="is-danger"
          >
          </b-icon>
        </b-table-column> -->

        <b-table-column
          field="status"
          :label="i18n.statuss[iso]"
          width="200"
          centered
          v-slot="props"
        >
          <!-- Estado de la suscripcion -->
          <b-tooltip
            :type="
              checkSubscription(props.row.ContratoId)
                ? 'is-success'
                : 'is-danger'
            "
          >
            <b-icon
              icon="user"
              pack="fas"
              size="is-small"
              :type="
                checkSubscription(props.row.ContratoId)
                  ? 'is-success'
                  : 'is-danger'
              "
              style="margin-right: 10px"
            >
            </b-icon>
            <template v-slot:content>
              <b
                >{{ i18n.subscription[iso] }}:
                {{
                  checkSubscription(props.row.ContratoId)
                    ? i18n.current[iso]
                    : i18n.expired[iso]
                }}</b
              ></template
            >
          </b-tooltip>

          <!-- Estado de la Membresia -->
          <b-tooltip
            :type="
              checkMembership(props.row.ContratoId) === 1
                ? 'is-success'
                : checkMembership(props.row.ContratoId) === 2
                ? 'is-danger'
                : 'is-dark'
            "
          >
            <b-icon
              icon="star"
              pack="fas"
              size="is-small"
              :type="
                checkMembership(props.row.ContratoId) === 1
                  ? 'is-success'
                  : checkMembership(props.row.ContratoId) === 2
                  ? 'is-danger'
                  : 'is-dark'
              "
              style="margin-right: 10px"
            >
            </b-icon>
            <template v-slot:content>
              <b
                >{{ i18n.membership[iso] }}:
                {{
                  checkMembership(props.row.ContratoId) === 1
                    ? i18n.active[iso]
                    : checkMembership(props.row.ContratoId) === 2
                    ? i18n.inactive[iso]
                    : i18n.pendingActivation[iso]
                }}</b
              ></template
            >
          </b-tooltip>

          <!-- Estado del contrato -->
          <b-tooltip
            :type="
              props.row.ContratoId.EstadoContrato === 1
                ? 'is-info'
                : props.row.ContratoId.EstadoContrato === 2
                ? 'is-success'
                : 'is-danger'
            "
          >
            <b-icon
              icon="file-contract"
              pack="fas"
              size="is-small"
              :type="
                props.row.ContratoId.EstadoContrato === 1
                  ? 'is-info'
                  : props.row.ContratoId.EstadoContrato === 2
                  ? 'is-success'
                  : 'is-danger'
              "
              style="margin-right: 10px"
            >
            </b-icon>
            <template v-slot:content>
              <b
                >{{ i18n.contract[iso] }}:
                {{
                  props.row.ContratoId.EstadoContrato === 1
                    ? i18n.pending[iso]
                    : props.row.ContratoId.EstadoContrato === 2
                    ? i18n.procesable[iso]
                    : i18n.cancelled[iso]
                }}</b
              ></template
            >
          </b-tooltip>
        </b-table-column>

        <b-table-column
          field="Numero"
          :label="i18n.contract[iso]"
          width="150"
          centered
          v-slot="props"
        >
          {{ (props.row.ContratoId && props.row.ContratoId.Numero) || "n/a" }}
        </b-table-column>

        <b-table-column
          field="user"
          label="LoginID"
          width="150"
          centered
          v-slot="props"
        >
          <div
            v-html="
              formatTextBySearch(
                props.row.ContratoId && props.row.ContratoId.LoginID,
                'LoginID'
              ) || `n/a`
            "
          ></div>
        </b-table-column>

        <b-table-column
          field="Nombre"
          :label="i18n.name[iso]"
          width="250"
          centered
          v-slot="propss"
        >
          <div
            v-html="
              `${formatTextBySearch(propss.row.Nombre, 'Nombre') || 'n/a'}`
            "
          ></div>
        </b-table-column>

        <b-table-column
          field="ApellidoPaterno"
          :label="i18n.lastname_ext[iso]"
          width="250"
          centered
          v-slot="propss"
        >
          <div
            v-html="
              `${
                formatTextBySearch(
                  propss.row.ApellidoPaterno,
                  'ApellidoPaterno'
                ) || 'n/a'
              }`
            "
          ></div>
        </b-table-column>

        <b-table-column
          field="club"
          label="Club"
          width="250"
          centered
          v-slot="props"
        >
          {{
            (props.row.ContratoId &&
              props.row.ContratoId.ClubId &&
              props.row.ContratoId.ClubId.Nombre) ||
            "n/a"
          }}
        </b-table-column>

        <!-- <b-table-column
          field="FechaRegistro"
          :label="i18n.registrationDate[iso]"
          width="250"
          centered
          v-slot="props"
        >
          <b-tag type="is-info is-light"
            ><b>{{
              formatDate(
                props.row.ContratoId && props.row.ContratoId.FechaRegistro
              ) || "n/a"
            }}</b></b-tag
          >
        </b-table-column>

        <b-table-column
          field="FechaCompra"
          :label="i18n.purchaseDate[iso]"
          width="250"
          centered
          v-slot="props"
        >
          <b-tag type="is-info is-light"
            ><b>{{
              formatDate(
                props.row.ContratoId && props.row.ContratoId.FechaCompra
              ) || "n/a"
            }}</b></b-tag
          >
        </b-table-column>

        <b-table-column
          field="FechaProcesable"
          :label="i18n.processingDate[iso]"
          width="250"
          centered
          v-slot="props"
        >
          <b-tag type="is-info is-light"
            ><b>{{
              formatDate(
                props.row.ContratoId && props.row.ContratoId.FechaProcesable
              ) || "n/a"
            }}</b></b-tag
          >
        </b-table-column> -->

        <b-table-column
          field="FechaProcesable"
          :label="i18n.renewalDate[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <b-tag type="is-info is-light"
            ><b>{{
              formatDate(
                props.row.ContratoId && props.row.ContratoId.FechaRenovacion
              ) || "n/a"
            }}</b></b-tag
          >
        </b-table-column>

        <b-table-column
          field="Idioma"
          :label="i18n.language[iso]"
          width="50"
          centered
          v-slot="props"
        >
          {{ props.row.Idioma || "n/a" }}
          <!-- <img
            :src="`img/${props.row.Idioma === 'EN' ? 'en' : 'es'}.png`"
            width="20"
            alt=""
          /> -->
        </b-table-column>
        <!-- 
        <b-table-column
          field="Agent"
          :label="i18n.agent[iso]"
          width="250"
          centered
          v-slot="props"
        >
          {{ props.row.ContratoId.Agent || "n/a" }}
        </b-table-column> -->

        <b-table-column
          field="FechaProcesable"
          :label="i18n.shippingStatus[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <div
            v-if="
              props.row.ContratoId &&
              props.row.ContratoId.EstadoEnvioRenewalOnline
            "
          >
            <b-tag
              type="is-info"
              v-if="props.row.ContratoId.EstadoEnvioRenewalOnline === 'pending'"
              >{{ props.row.ContratoId.EstadoEnvioRenewalOnline }}</b-tag
            >
            <b-tag
              type="is-success"
              v-if="props.row.ContratoId.EstadoEnvioRenewalOnline === 'sent'"
              >{{ props.row.ContratoId.EstadoEnvioRenewalOnline }}</b-tag
            >
            <b-tag
              type="is-danger"
              v-if="props.row.ContratoId.EstadoEnvioRenewalOnline === 'error'"
              >{{ props.row.ContratoId.EstadoEnvioRenewalOnline }}</b-tag
            >
          </div>
        </b-table-column>
        <b-table-column
          field="FechaProcesable"
          :label="i18n.shippingDate[iso]"
          width="150"
          centered
          v-slot="props"
        >
          <div
            v-if="
              props.row.ContratoId &&
              props.row.ContratoId.FechaEnvioRenewalOnline
            "
          >
            <b-tag type="is-info is-light"
              ><b>{{
                formatDateAgo(
                  props.row.ContratoId &&
                    props.row.ContratoId.FechaEnvioRenewalOnline
                ) || ""
              }}</b></b-tag
            >
          </div>
        </b-table-column>

        <b-table-column label="Actions" v-slot="props" centered width="170">
          <b-tooltip type="is-success is-light">
            <b-button
              icon-left="eye"
              icon-pack="fas"
              size="is-small"
              type="is-success is-light"
              @click="goPath(`/membership-profile?id=${props.row._id}`)"
              style="margin-right: 10px"
            ></b-button>
            <template v-slot:content>
              <b>{{ i18n.viewContract[iso] }}</b></template
            >
          </b-tooltip>
        </b-table-column>

        <template #footer>
          <div class="columns">
            <div class="column is-11"></div>

            <div class="column is-1">
              <b-field :label="i18n.quantity[iso]">
                <!-- @input="savePerPage" -->
                <b-select v-model="perPage" expanded>
                  <option :value="5">5</option>
                  <option :value="10">10</option>
                  <option :value="20">20</option>
                  <option :value="50">50</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </template>
      </b-table>
      <b-modal
        v-model="isActiveModalEditMember"
        has-modal-card
        trap-focus
        aria-role="dialog"
        aria-modal
      >
        <template #default="props">
          <modal-edit-member
            :__member="dataModalEditMember"
            @refresh="listAllMembers"
            @close="props.close"
          ></modal-edit-member>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
import axios from "axios";
const API_URL = process.env.VUE_APP_API_URL;
import moment from "moment";
import i18n, { agent, type } from "../../utils/i18n";

import ModalEditMember from "../ModalEditMember.vue";
// import ModalCommentMember from "./ModalCommentMember.vue";

const today = new Date();

export default {
  data() {
    return {
      isPaginated: true,
      isPaginationSimple: true,
      isPaginationRounded: false,
      paginationPosition: "bottom",
      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
      currentPage: 1,
      perPage: 10,
      hasInput: false,
      paginationOrder: "",
      inputPosition: "",
      inputDebounce: "",
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: true,
      isFocusable: false,
      hasMobileCards: true,
      total: 0,
      loadingExportMembers: false,

      //i18n
      i18n,
      iso: null,

      //Permissions
      permissions: "",

      //Filters
      tabFilters: 0,
      searchMembers: null,
      passValidateSearch: false,
      lastSearchMembers: null,
      clubesName: [],
      staff: [],
      selectedStaff: null,
      loadingClubes: false,
      itemsFilters: [],
      checkedRows: [],
      advancedFilters: {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaProcesable: [],
        FechaRenovacion: [],
        FechaCancelacion: [],
        FechaCompra: [],
        club: null,
        status: null,
        // membershipStatus: 1,
        // onlyHeadlines: false,
        language: null,
        contractStatus: 2,
        staff: null,
      },
      validateAdvancedFilters: {
        FechaRegistro: false,
        FechaProcesamiento: false,
        FechaCancelacion: false,
        FechaRenovacion: false,
        FechaCompra: false,
        EstadoMembresia: false,
        EstadoContrato: false,
      },

      /**
       * Members
       */

      members: [],
      loadingMembers: false,
      LimiteRenovacionDisponible: 0,
      LimiteRenovacionTotal: 0,
      LimiteRestableceRenovaciones: null,
      loadingSettings: false,

      /**
       * Modal
       */

      isActiveModalEditMember: false,
      dataModalEditMember: null,
      isActiveModalCommentMember: false,
      memberIdModalCommentMember: null,

      /**
       * Dates
       */
      date: new Date(),
      minDate: new Date(
        today.getFullYear() - 80,
        today.getMonth(),
        today.getDate()
      ),
      maxDate: new Date(
        today.getFullYear() + 18,
        today.getMonth(),
        today.getDate()
      ),
    };
  },
  async created() {
    this.iso = localStorage.getItem("iso");
    this.$bus.on("change-iso", () => {
      this.iso = localStorage.getItem("iso");
    });

    //PerPage
    const perPageLocalStorage = localStorage.getItem("perPageReserves");
    if (!perPageLocalStorage) this.savePerPage();
    else this.perPage = perPageLocalStorage;

    //Permissions
    this.permissions = localStorage.getItem("role");

    //Tabs
    const tabFilters = localStorage.getItem("tabFilters");
    if (tabFilters === 1 || tabFilters === "1") this.tabFilters = 1;

    //Members
    await this.listSettigns();
    await this.listAllClubes();
    await this.listAllUsersStaff();

    // let stateMembers = localStorage.getItem("members");
    // stateMembers = JSON.parse(stateMembers);
    // console.log("stateMembers", stateMembers);
    // if (
    //   stateMembers &&
    //   stateMembers?.exp &&
    //   stateMembers.exp > moment().valueOf() &&
    //   stateMembers.lastSearchMembers &&
    //   stateMembers.lastSearchMembers !== "null"
    // ) {
    //   this.searchMembers = stateMembers.lastSearchMembers;
    //   this.passValidateSearch = true;
    //   this.listAllMembers({}, true, stateMembers);
    // } else this.advancedSearch(false);

    // await this.advancedSearch(false);
  },
  components: {
    ModalEditMember,
    // ModalCommentMember,
  },
  methods: {
    async listAllMembers(filters = {}, isState = false, state = {}) {
      try {
        console.log("isState", isState);
        await this.listSettigns();

        if (isState) {
          this.members = state.data;
          this.total = state.total;
          this.lastSearchMembers = state.lastSearchMembers;
          this.itemsFilters = state.itemsFilters;
        } else {
          this.loadingMembers = true;
          let query = `?limit=${this.perPage}&page=${this.currentPage}&listAll=y&genReplicate=n`;
          const { data } = await axios.post(
            `${API_URL}/catcher/members/list-all${query}`,
            filters
          );
          console.log("members", data);
          this.members = data?.members || [];
          this.total = data.paginate.total;
          this.lastSearchMembers = `${this.searchMembers}`;
          this.itemsFilters = data?.itemsFilters;
          this.loadingMembers = false;

          /**
           * State
           */
          // const stateMembers = {
          //   data: this.members,
          //   exp: moment().add(20, "minute").valueOf(),
          //   total: this.total,
          //   lastSearchMembers: this.lastSearchMembers,
          //   itemsFilters: this.itemsFilters,
          // };
          // localStorage.setItem("members", JSON.stringify(stateMembers));
        }
      } catch (err) {
        this.loadingMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos",
          type: "is-danger",
        });
      }
    },
    async listSettigns() {
      try {
        this.loadingSettings = true;
        const { data } = await axios.get(`${API_URL}/settings`);
        console.log("settings", data?.settings);
        this.settings = data?.settings;

        const UltimoEnvioRenovaciones =
          data?.settings?.UltimoEnvioRenovaciones || 0;

        this.LimiteRenovacionTotal =
          data?.settings?.LimiteEnviosRenovaciones || 0;

        this.LimiteRenovacionDisponible =
          this.LimiteRenovacionTotal - UltimoEnvioRenovaciones;

        this.LimiteRestableceRenovaciones =
          data?.settings?.ProximaFechaDisponibleEnviosRenovaciones || null;
        this.loadingSettings = false;
      } catch (err) {
        console.log(err);
        this.loadingSettings = false;
        this.$buefy.toast.open({
          message: "Error al consultar ajustes",
          type: "is-danger",
        });
      }
    },
    savePerPage() {
      // localStorage.setItem("perPageReserves", this.perPage);
      // this.listAllMembers();
    },
    validateSearch(text) {
      // return true;
      // this.passValidateSearch = /^[A-Za-z0-9]+$/gi.test(text);
      this.passValidateSearch = true;
    },
    clearInputSearch() {
      this.searchMembers = null;
      this.passValidateSearch = false;
      this.listAllMembers();
    },
    formatTextBySearch(text, field) {
      if (this.itemsFilters.includes(field)) {
        if (this.lastSearchMembers && text && text !== "") {
          const rgx = new RegExp(this.lastSearchMembers, "ig");
          const insertHtml = (match) => {
            return `<b style="color: #167df0;">${match}</b>`;
          };
          const __name = text.replace(rgx, insertHtml);

          return __name;
        } else {
          return text;
        }
      } else {
        return text;
      }
    },
    openModalEditMember(member) {
      this.dataModalEditMember = member;
      this.isActiveModalEditMember = true;
    },
    restorePassword() {
      this.$buefy.dialog.confirm({
        title: this.i18n.resetPassword[this.iso],
        message: this.i18n.resetPassword__confirm[this.iso],
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-info is-light",
        onConfirm: () =>
          this.$buefy.toast.open(
            this.i18n.resetPassword__confirm__success[this.iso]
          ),
      });
    },
    openModalCommentMember(memberId) {
      this.memberIdModalCommentMember = memberId;
      this.isActiveModalCommentMember = true;
    },
    goPath(path) {
      this.$router.push(path);
    },
    changeTab() {
      // localStorage.setItem("tabFilters", this.tabFilters);
    },
    async listAllClubes() {
      try {
        this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/club/list`, {
          select: "Nombre",
        });
        this.clubesName = data?.clubes || [];
        this.loadingClubes = false;
      } catch (err) {
        this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de clubes",
          type: "is-danger",
        });
      }
    },
    advancedSearch(isExport = false) {
      if (isExport) {
        this.exportAllMembers(this.advancedFilters);
      } else {
        this.currentPage = 1;
        this.listAllMembers(this.advancedFilters);
      }
    },
    basicSearch(isExport = false) {
      if (isExport) {
        if (this.searchMembers && this.searchMembers !== "")
          this.exportAllMembers({
            rgx: this.searchMembers,
          });
        else this.exportAllMembers({});
      } else {
        this.currentPage = 1;
        if (this.searchMembers && this.searchMembers !== "")
          this.listAllMembers({
            rgx: this.searchMembers,
          });
      }
    },
    checkAdvancedSearch() {
      let result = false;
      for (let i in this.advancedFilters) {
        if (this.advancedFilters[i]) result = true;
      }
      return result;
    },
    onPageChange(page) {
      this.currentPage = page;
      // console.log("checkAdvancedSearch()", checkAdvancedSearch());
      if (this.searchMembers && this.searchMembers !== "") {
        this.listAllMembers({
          rgx: this.searchMembers,
        });
      } else if (this.checkAdvancedSearch(false)) {
        this.listAllMembers(this.advancedFilters);
      } else {
        this.listAllMembers();
      }
    },
    clearAdvancedSearch() {
      this.advancedFilters = {
        Nombre: null,
        ApellidoPaterno: null,
        Email: null,
        LoginID: null,
        nroContract: null,
        FechaRegistro: [],
        FechaCancelado: [],
        FechaRenovacion: [],
        club: null,
        status: null,
        onlyHeadlines: null,
        language: null,
        contractStatus: 2,
        staff: null,
      };
      this.advancedSearch();
    },
    getMembersStatic(data) {
      let result = [];
      result.push({
        FullNombre: `${data.Nombre} ${data.ApellidoPaterno}`,
        Nombre: data.Nombre,
        ApellidoPaterno: data.ApellidoPaterno,
        Telefono: data.Telefono,
        Email: data.Email,
        Language: data.ContratoId?.Idioma,
        TipoSocioId: data.TipoSocio,
      });

      if (data?.coOwner)
        result.push({
          FullNombre: `${data.coOwner.Nombre} ${data.coOwner.ApellidoPaterno}`,
          Nombre: data.coOwner.Nombre,
          ApellidoPaterno: data.coOwner.ApellidoPaterno,
          Telefono: data.coOwner.Telefono,
          Email: data.coOwner.Email,
          Language: data.ContratoId?.Idioma,
          TipoSocioId: data.coOwner.TipoSocio,
        });

      return result;
    },
    formatDate(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).format("MMM/DD/YYYY").toUpperCase();
    },
    formatDateAgo(date) {
      if (!date || date === 0) return "";
      moment.locale(this.iso);
      return moment(date).fromNow();
    },
    formatDateLimitRenewal(date) {
      if (!date) return "";
      moment.locale(this.iso);
      return moment(date).fromNow();
    },
    getBeneficiariesStatic(data) {
      let result = [];
      if (data?.beneficiaries)
        result = [
          data.beneficiaries
            .map(
              (b) =>
                `${this.formatTextBySearch(
                  b.Nombre,
                  "Nombre"
                )} ${this.formatTextBySearch(
                  b.ApellidoPaterno,
                  "ApelidoPaterno"
                )}`
            )
            .join(", "),
        ];

      console.log("result", result);
      return result.filter((b) => b && b !== "");
    },
    async exportAllMembers(filters = {}) {
      try {
        this.loadingExportMembers = true;
        let query = `?export=s`;
        const { data } = await axios.post(
          `${API_URL}/catcher/members/list-all${query}`,
          filters
        );
        console.log("members", data);

        const blob = new Blob([data.members.csv], { type: "text/csv" });
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.setAttribute("href", url);
        a.setAttribute("download", "members.csv");
        a.click();
        this.loadingExportMembers = false;
      } catch (err) {
        this.loadingExportMembers = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al exportar datos",
          type: "is-danger",
        });
      }
    },
    checkValidateAdvancedFilters() {
      if (this.advancedFilters.contractStatus === 1) {
        this.validateAdvancedFilters.FechaCancelacion = true;
        this.validateAdvancedFilters.FechaRenovacion = true;

        this.advancedFilters.FechaCancelacion = [];
        this.advancedFilters.FechaRenovacion = [];
      } else {
        this.validateAdvancedFilters.FechaCancelacion = false;
        this.validateAdvancedFilters.FechaRenovacion = false;
      }
    },
    async listAllUsersStaff() {
      try {
        // this.loadingClubes = true;
        const { data } = await axios.post(`${API_URL}/system/list-staff`);
        this.staff = data?.users || [];
        // this.loadingClubes = false;
      } catch (err) {
        // this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error al consultar datos de staff",
          type: "is-danger",
        });
      }
    },
    checkContract(a, b) {
      // console.log("row", this.checkedRows);
      return true;
    },
    async activateAllContractsFetch() {
      try {
        // this.loadingClubes = true;
        await axios.put(
          `${API_URL}/catcher/edit-status-membership-massive/contract`,
          {
            contractIds: this.checkedRows.map((c) => c.ContratoId.Id),
          }
        );
        this.$buefy.toast.open({
          message: this.i18n.massiveActivateContracts__msg1[this.iso],
          type: "is-success",
        });
        this.checkedRows = [];
        this.advancedSearch();
        // this.loadingClubes = false;
      } catch (err) {
        // this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error",
          type: "is-danger",
        });
      }
    },
    async activateAllContracts() {
      if (this.checkedRows.length > 0) {
        this.$buefy.dialog.confirm({
          message: this.i18n.massiveActivateContracts__msg2[this.iso].replace(
            "{{total}}",
            this.checkedRows.length
          ),
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-success",
          onConfirm: async () => await this.activateAllContractsFetch(),
        });
      }
    },
    async assignStaffToContractsFetch() {
      if (this.checkedRows.length > 0) {
        try {
          // this.loadingClubes = true;
          await axios.post(`${API_URL}/catcher/assign-staff/contract`, {
            contractIds: this.checkedRows.map((c) => c.ContratoId.Id),
            staff: this.selectedStaff,
          });
          this.$buefy.toast.open({
            message: this.i18n.massiveAssignContracts__msg1[this.iso],
            type: "is-success",
          });
          this.checkedRows = [];
          this.selectedStaff = null;
          this.advancedSearch();
          // this.loadingClubes = false;
        } catch (err) {
          // this.loadingClubes = false;
          console.log(err);
          this.$buefy.toast.open({
            message: "Error",
            type: "is-danger",
          });
        }
      }
    },
    async assignStaffToContracts() {
      if (this.checkedRows.length > 0 && this.selectedStaff) {
        this.$buefy.dialog.confirm({
          message: this.i18n.massiveAssignContracts__msg2[this.iso]
            .replace("{{total}}", this.checkedRows.length)
            .replace("{{staff}}", this.selectedStaff),
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-success",
          onConfirm: async () => await this.assignStaffToContractsFetch(),
        });
      }
    },
    checkSubscription(contract) {
      const now = Date.now();
      if (
        contract &&
        contract?.FechaRenovacion &&
        contract?.FechaRenovacion < now
      )
        return false;
      else return true;
    },
    checkMembership(contract) {
      if (contract && contract?.EstadoMembresia === true) return 1;
      else if (contract && contract?.EstadoMembresia === false) return 2;
      else return 3;
    },

    async sendAllEmailsRenewalsFetch(contractIds) {
      try {
        // this.loadingClubes = true;
        await axios.post(`${API_URL}/renewals/send-online-massive`, {
          // contractIds: this.checkedRows.map((c) => c.ContratoId.Id),
          contractIds,
          agent: localStorage.getItem("user") || "na",
        });
        this.$buefy.dialog.alert({
          title: this.i18n.onlineRenewalsSent__title[this.iso],
          message: this.i18n.onlineRenewalsSent__msg1[this.iso],
          confirmText: "OK!",
          type: "is-success",
          // onConfirm: async () => document.location.reload(),
        });
        this.checkedRows = [];
        this.advancedSearch(false);
        // this.loadingClubes = false;
      } catch (err) {
        // this.loadingClubes = false;
        console.log(err);
        this.$buefy.toast.open({
          message: "Error",
          type: "is-danger",
        });
      }
    },
    async sendAllEmailsRenewals() {
      console.log("this.checkedRows", this.checkedRows);
      if (this.checkedRows.length > 0) {
        this.$buefy.dialog.confirm({
          message: `${this.i18n.sendEmailRenewals[this.iso].replace(
            "{{total}}",
            this.checkedRows.reduce((acum, item) => {
              let tmp = 0;
              if (item?.Email) tmp += 1;
              if (item?.coOwner?.Email) tmp += 1;
              return acum + tmp;
            }, 0)
          )} (${this.i18n.ownerAndCoOwner[this.iso]})`,
          cancelText: this.i18n.cancel[this.iso],
          confirmText: this.i18n.accept[this.iso],
          type: "is-success",
          onConfirm: async () =>
            await this.sendAllEmailsRenewalsFetch(
              this.checkedRows.map((c) => c.ContratoId.Id)
            ),
        });
      }
    },
    async sendAllEmailsRenewalsAll() {
      this.$buefy.dialog.confirm({
        message: `${this.i18n.sendEmailRenewalsAll[this.iso].replace(
          "{{total}}",
          this.checkedRows.length
        )} (${this.i18n.ownerAndCoOwner[this.iso]})`,
        cancelText: this.i18n.cancel[this.iso],
        confirmText: this.i18n.accept[this.iso],
        type: "is-success",
        onConfirm: async () =>
          await this.sendAllEmailsRenewalsFetch(
            this.members.map((c) => c.ContratoId.Id)
          ),
      });
    },
    checkAll(value) {
      if (value && value.length > 0) {
        console.log("add");
        this.checkedRows = [...this.members];
      } else {
        console.log("remove");
        this.checkedRows = [];
      }
      console.log("value", value);
      console.log("this.checkedRows", this.checkedRows);
      this.$forceUpdate();
    },
  },
};
</script>
