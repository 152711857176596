// const { default: ReAssignment } = require("@/components/ReAssignment.vue");

const { duration } = require("moment");

module.exports = {
  changeIso: {
    es: "¿Desea cambiar el idioma?",
    en: "Do you want to change the language?",
  },
  logout: {
    es: "Cerrar sesión",
    en: "Log out",
  },
  weeks: {
    es: "Semanas",
    en: "Weeks",
  },
  reserves: {
    es: "Reservas",
    en: "Bookings",
  },
  search: {
    es: "Busqueda",
    en: "Search",
  },
  resetSearch: {
    es: "Restablecer Busqueda",
    en: "Reset Search",
  },
  invetoryType: {
    es: "Tipo de Inventario",
    en: "Inventory Type",
  },
  all: {
    es: "Todos",
    en: "All",
  },
  country: {
    es: "País",
    en: "Country",
  },
  bestSellers: {
    es: "Más Vendidos",
    en: "Best Sellers",
  },
  state: {
    es: "Estado",
    en: "State",
  },

  city: {
    es: "Ciudad",
    en: "City",
  },
  filters: {
    es: "Filtros",
    en: "Filters",
  },
  quantity: {
    es: "Cantidad",
    en: "Quantity",
  },
  copyXml: {
    es: "Copiar XML",
    en: "Copy XML",
  },
  spanish: {
    es: "Español",
    en: "Spanish",
  },
  english: {
    es: "Ingles",
    en: "English",
  },
  addPayExternal: {
    es: "Agregar Pago Externo",
    en: "Add External Payment",
  },
  add: {
    es: "Agregar",
    en: "Add",
  },
  cancel: {
    es: "Cancelar",
    en: "Cancel",
  },
  typePayment: {
    es: "Tipo de Pago",
    en: "Payment Type",
  },
  idTransaction: {
    es: "ID Transacción",
    en: "Transaction ID",
  },
  amount: {
    es: "Monto",
    en: "Amount",
  },
  findByResort: {
    es: "Buscar por resort",
    en: "Search by resort",
  },
  cardHolder: {
    es: "Titular de la tarjeta",
    en: "Cardholder",
  },
  emailAgent: {
    es: "Correo del agente",
    en: "Agent's email",
  },

  noResultsFound: {
    es: "No se encontro resultados",
    en: "No results found",
  },
  hide: {
    es: "Ocultar",
    en: "Hide",
  },
  open: {
    es: "Abrir",
    en: "Open",
  },
  amenities: {
    es: "Amenidades",
    en: "Amenities",
  },
  acceptUpdateTraveler: {
    es: "¿Desea continuar con la actualización?",
    en: "Do you want to continue with the update?",
  },
  yes: {
    es: "Si",
    en: "Yes",
  },
  sendConfirmation: {
    es: "Enviar Confirmación",
    en: "Send Confirmation",
  },
  client: {
    es: "Cliente",
    en: "Client",
  },
  addAEmail: {
    es: "Agrega un email",
    en: "Add an email",
  },
  onlySendToCopies: {
    es: "Solo enviar a copias",
    en: "Only send to copies",
  },
  send: {
    es: "Enviar",
    en: "Send",
  },
  blockedByPermissions: {
    es: "Bloqueado por permisos",
    en: "Blocked by permissions",
  },
  name: {
    es: "Nombre",
    en: "Name",
  },
  fullname: {
    es: "Nombre Completo",
    en: "Full Name",
  },
  lastname: {
    es: "Apellido",
    en: "Last name",
  },
  lastname_ext: {
    es: "Apellido Paterno",
    en: "Last name",
  },
  mothersLastname: {
    es: "Apellido Materno",
    en: "Mother's last name",
  },
  email: {
    es: "Correo Electrónico",
    en: "Email",
  },
  birthdate: {
    es: "Fecha de nacimiento",
    en: "Birthdate",
  },
  codCellphone: {
    es: "Código Celular",
    en: "Cellphone Code",
  },
  cellphone: {
    es: "Celular",
    en: "Cellphone",
  },
  codPhone: {
    es: "Código Teléfono",
    en: "Phone Code",
  },
  phone: {
    es: "Teléfono",
    en: "Phone",
  },
  address: {
    es: "Dirección",
    en: "Address",
  },
  ipAddress: {
    es: "Dirección IP",
    en: "IP Address",
  },
  zip: {
    es: "Código Postal",
    en: "Zip",
  },
  contract: {
    es: "Contrato",
    en: "Contract",
  },
  language: {
    es: "Idioma",
    en: "Language",
  },
  settings: {
    es: "Ajustes",
    en: "Settings",
  },
  payments: {
    es: "Pagos",
    en: "Payments",
  },
  catcher: {
    es: "Capturador",
    en: "Capturer",
  },
  renewals: {
    es: "Renovaciones",
    en: "Renewals",
  },
  renewal: {
    es: "Renovacion",
    en: "Renewal",
  },
  online: {
    es: "Online",
    en: "Online",
  },
  statistics: {
    es: "Estadísticas",
    en: "Statistics",
  },
  advancedSearch: {
    es: "Busqueda Avanzada",
    es: "Advanced Search",
  },
  followUpRenewals: {
    es: "Seguimiento",
    en: "Follow-up",
  },
  customerPortfolio: {
    es: "Cartera de Clientes",
    en: "Customer Portfolio",
  },
  members: {
    es: "Socios",
    en: "Members",
  },
  creationDate: {
    es: "Fecha de Creación",
    en: "Creation Date",
  },
  expiredAt: {
    es: "Fecha de expiración",
    en: "Expiration date",
  },
  edit: {
    es: "Editar",
    en: "Edit",
  },
  comments: {
    es: "Comentarios",
    en: "Comments",
  },
  messageValidateSearch: {
    es: "No esta permitido caracteres especiales ni espacios en blanco",
    en: "Not allowed special characters or blank spaces",
  },
  editMember: {
    es: "Editar socio",
    en: "Edit member",
  },
  codCountry: {
    es: "Código de País",
    en: "Country code",
  },
  createdBy: {
    es: "Creado por",
    en: "Created By",
  },
  resetPassword: {
    es: "Restablecer contraseña",
    en: "Restore password",
  },
  resetPassword__confirm: {
    es: "Se le enviará un correo al usuario con la nueva contraseña",
    en: "An email will be sent to the user with the new password",
  },
  resetPassword__confirm__success: {
    es: "Contraseña enviada",
    en: "Password sent",
  },
  accept: {
    es: "Aceptar",
    en: "Accept",
  },
  addComment: {
    es: "Agrega un comentario",
    en: "Add a comment",
  },
  noComment: {
    es: "Sin comentarios",
    en: "No comment",
  },
  settingsPayments: {
    es: "Configuración de pagos",
    en: "Payments settings",
  },
  createNewPayment: {
    es: "Crear método de pago",
    en: "Create payment method",
  },
  paymentMethod: {
    es: "Método de pago",
    en: "Payment method",
  },
  stripePaymentIntentID: {
    es: "ID de Intento de Pago de Stripe",
    en: "Stripe Payment Intent ID",
  },
  stripeChangeID: {
    es: "ID de Cambio de Stripe",
    en: "Stripe Charge ID",
  },
  save: {
    es: "Guardar",
    en: "Save",
  },
  code: {
    es: "Código",
    en: "Code",
  },
  description: {
    es: "Descripción",
    en: "Description",
  },
  publicToken: {
    es: "Token publico",
    en: "Public token",
  },
  test: {
    es: "Prueba",
    en: "Test",
  },
  privateToken: {
    es: "Token privado",
    en: "Private token",
  },
  currency: {
    es: "Divisa",
    en: "Currency",
  },
  main: {
    es: "Principal",
    en: "Main",
  },
  status: {
    es: "Estado",
    en: "Status",
  },
  statuss: {
    es: "Estados",
    en: "Status",
  },
  deletePaymentMethod: {
    es: "Eliminar método de pago",
    en: "Remove payment method",
  },
  addMainPaymentMethod: {
    es: "Establecer como principal",
    en: "Set as main",
  },
  textDeletePaymentMethod: {
    es: "Al eliminar este método de pago los enlaces de pagos que ya fueron generados con esta cuenta se mantendrán activas.",
    en: "By eliminating this payment method, payment links that were already generated with this account will remain active.",
  },
  textSetPaymentMethod: {
    es: "Al cambiar el método de pago predeterminado, los enlaces de pagos que ya fueron generados no cambiaran de cuenta.",
    en: "When changing the default payment method, the payment links that were already generated will not change their account.",
  },
  doYouWishToContinue: {
    es: "¿Desea Continuar?",
    en: "Do you wish to continue?",
  },
  fastSearch: {
    es: "Búsqueda Rápida",
    en: "Fast Search",
  },
  advancedSearch: {
    es: "Búsqueda Avanzada",
    en: "Advanced Search",
  },
  registrationDate: {
    es: "Fecha de Registro",
    en: "Registration Date",
  },
  processingDate: {
    es: "Fecha de Procesamiento",
    en: "Processing Date",
  },
  renewalDate: {
    es: "Fecha de Renovación",
    en: "Renewal Date",
  },
  membershipStatus: {
    es: "Estado de la Membresía",
    en: "Membership Status",
  },
  contractStatus: {
    es: "Estado del Contrato",
    en: "Contract Status",
  },
  current: {
    es: "Vigente",
    en: "Current",
  },
  bookings: {
    es: "Reservaciones",
    en: "Bookings",
  },
  expired: {
    es: "Expirado",
    en: "Expired",
  },
  viewProfile: {
    es: "Ver Perfil",
    en: "View Profile",
  },
  memberProfile: {
    es: "Perfil Socio",
    en: "Member Profile",
  },
  details: {
    es: "Detalles",
    en: "Details",
  },
  accountStatus: {
    es: "Estado de Cuenta",
    en: "Account Status",
  },
  renovations: {
    es: "Renovaciones",
    en: "Renewals",
  },
  documents: {
    es: "Documentos",
    en: "Documents",
  },
  memberType: {
    es: "Tipo de Socio",
    en: "Member Type",
  },
  onlyHeadlines: {
    es: "Solo titulares",
    en: "Only Headlines",
  },
  owner: {
    es: "Titular",
    en: "Owner",
  },
  coOwner: {
    es: "Co-Titular",
    en: "Co-Owner",
  },
  viewContract: {
    es: "Ver Contrato",
    en: "View Contract",
  },
  contractNumber: {
    es: "Número de Contrato",
    en: "Contract number",
  },
  years: {
    es: "Años",
    en: "Years",
  },
  LoginID: {
    es: "LoginID",
    en: "LoginID",
  },
  processableDate: {
    es: "Fecha de Procesamiento",
    en: "Process Date",
  },
  fee: {
    es: "Tarifa",
    en: "Fee",
  },
  period: {
    es: "Periodo",
    en: "Billing",
  },
  nextInvoice: {
    es: "Periodo Anual - Proxima Factura en",
    en: "Billing Annual - Next Invoice on",
  },
  for: {
    es: "de",
    en: "for ",
  },
  periodRenew: {
    es: "Periodo Renovación",
    en: "Renewal Period",
  },
  startEndPeriod: {
    es: "Inicio - Fin Periodo",
    en: "Start - End Period",
  },
  purchaseDate: {
    es: "Fecha de Compra",
    en: "Purchase Date",
  },
  annuaPlan: {
    es: "Plan Anual",
    en: "Annual Plan",
  },
  renovationDate: {
    es: "Fecha de Renovación",
    en: "Renovation Date",
  },
  activationDate: {
    es: "Fecha de Activación",
    en: "Activation Date",
  },
  cancellationDate: {
    es: "Fecha de Cancelación",
    en: "Cancellation date",
  },
  emitDate: {
    es: "Fecha de emisión",
    en: "Emit date",
  },
  number: {
    es: "Numero",
    en: "Number",
  },
  nroContract: {
    es: "Numero de contrato",
    en: "Contract number",
  },
  rci: {
    es: "RCI",
    en: "RCI",
  },
  fingerprint: {
    es: "Huella digital",
    en: "Fingerprint",
  },
  showMoreResults: {
    es: "Mostrar más resultados",
    en: "Show more results",
  },
  noMoreResults: {
    es: "No hay más resultados",
    en: "There are no more results",
  },
  findLoginId: {
    es: "Buscar socios por LoginID",
    en: "Search members by LoginID",
  },
  nationality: {
    es: "Nacionalidad",
    en: "Nationality",
  },
  civilStatus: {
    es: "Estado Civil",
    en: "Marital Status",
  },
  charge: {
    es: "Cargo",
    en: "Charge",
  },
  payDay: {
    es: "Fecha de Pago",
    en: "Payment Day",
  },
  renewalAssign: {
    es: "Asignar a agente",
    en: "Assign to agent",
  },
  dateStartPeriod: {
    es: "Fecha Inicio Periodo",
    en: "Date start period",
  },
  dateEndPeriod: {
    es: "Fecha Fin Periodo",
    en: "Date end period",
  },
  periods: {
    es: "Periodos",
    en: "Periods",
  },
  payConfirm: {
    es: "Referencia de Pago",
    en: "Payment Reference",
  },
  onlineRenewals: {
    es: "Renovaciones en línea",
    en: "Online renewals",
  },
  broadcastDate: {
    es: "Fecha de Emisión",
    en: "Start date",
  },
  paid: {
    es: "Pagado",
    en: "Paid",
  },
  paidDate: {
    es: "Fecha de Pago",
    en: "Payment Date",
  },
  topic: {
    es: "Asunto",
    en: "Subject",
  },
  validateMsgTopic: {
    es: "Selecciona un tema para enviar el comentario",
    en: "Select a topic to send the comment",
  },
  sendLink: {
    es: "Enviar enlace",
    en: "Send link",
  },
  languageUser: {
    es: "Idioma del Socio",
    en: "Language of the Member",
  },
  pending: {
    es: "Pendiente",
    en: "Pending",
  },
  procesable: {
    es: "Procesable",
    en: "Processable",
  },
  cancelled: {
    es: "Cancelado",
    en: "Canceled",
  },
  beneficiaries: {
    es: "Friends and Family",
    en: "Friends and Family",
  },
  exportToCsv: {
    es: "Exportar CSV",
    en: "Export CSV",
  },
  goBack: {
    es: "Volver",
    en: "Go Back",
  },
  renew: {
    es: "Link de Pago",
    en: "Payment Link",
  },
  addPayment: {
    es: "Agregar Pago",
    en: "Add Payment",
  },
  renew__message1: {
    es: "Introduzca la cantidad de años",
    en: "Enter the number of years",
  },
  renew__message2: {
    es: "Introduzca el código de descuento (Opcional)",
    en: "Enter the discount code (Optional)",
  },
  renew__message3: {
    es: "Introduzca el ID del contrato",
    en: "Enter the Contract ID",
  },
  next: {
    es: "Siguiente",
    en: "Next",
  },

  paymentsLinks: {
    es: "Enlaces de Pago",
    en: "Payments Links",
  },
  paymentLink: {
    es: "Enlace de Pago",
    en: "Payment Link",
  },
  createPaymentsLinks: {
    es: "Crear enlaces de pago",
    en: "Create payment links",
  },
  reservation: {
    es: "Reservacion",
    en: "Reservation",
  },
  createPaymentsLinks__msgOk: {
    es: "Enlace de pago creado con éxito",
    en: "Payment link created successfully",
  },
  createPaymentsLinks__msg1: {
    es: "Ingresa la referencia de la reservación",
    en: "Enter the reservation reference",
  },
  createPaymentsLinks__msg2: {
    es: "Ingresa el correo electrónico",
    en: "Enter email",
  },
  changeStatusMembership: {
    es: "Cambiar estado de la membresía",
    en: "Change a membership status",
  },
  changeStatusContract: {
    es: "Cambiar estado del contrato",
    en: "Change contract status",
  },
  active: {
    es: "Activo",
    en: "Active",
  },
  inactive: {
    es: "Inactivo",
    en: "Inactive",
  },
  actions: {
    es: "Acciones",
    en: "Actions",
  },
  linkCopy__msg1: {
    es: "Enlace copiado con éxito",
    en: "Link copied successfully",
  },
  linkCopy__msg2: {
    es: "Enlace enviado con éxito",
    en: "Link sent successfully",
  },
  system: {
    es: "Sistema",
    en: "System",
  },
  users: {
    es: "Usuarios",
    en: "Users",
  },
  user: {
    es: "Usuario",
    en: "User",
  },
  roles: {
    es: "Roles",
    en: "Roles",
  },
  activations: {
    es: "Activaciones",
    en: "Activations",
  },
  assignmentActivation: {
    es: "Asignación / Activación",
    en: "Assignment / Activation",
  },
  assignedAgent: {
    es: "Agente asignado",
    en: "Assigned agent",
  },
  assign: {
    es: "Asignar",
    en: "Assign",
  },
  activate: {
    es: "Activar",
    en: "Activate",
  },
  selectAgent: {
    es: "Seleccione un agente",
    en: "Select an agent",
  },
  agent: {
    es: "Agente",
    en: "Agent",
  },
  massiveActivateContracts__msg1: {
    es: "Contratos activos con éxito",
    en: "Successfully active contracts",
  },
  massiveActivateContracts__msg2: {
    es: "¿Deseas activar {{total}} contratos seleccionados?",
    en: "Do you want to activate {{total}} selected contracts?",
  },
  massiveAssignContracts__msg1: {
    es: "Contratos asignados con éxito",
    en: "Successfully assigned contracts",
  },
  massiveAssignContracts__msg2: {
    es: "¿Deseas asignar {{total}} contratos a {{staff}}?",
    en: "Do you want to assign {{total}} contracts to {{staff}}?",
  },
  subscriptionStatus: {
    es: "Estado de la Suscripción",
    en: "Subscription Status",
  },
  subscription: {
    es: "Suscripción",
    en: "Subscription",
  },
  sendEmailMembershipStatus: {
    es: "Enviar correo de bienvenida",
    en: "Send welcome email",
  },
  addTraveler: {
    es: "Agregar Viajero",
    en: "Add Traveler",
  },
  exportToExcel: {
    es: "Exportar a Excel",
    en: "Export to Excel",
  },
  accessDenied: {
    es: "Acceso denegado",
    en: "Access denied",
  },
  notFound404: {
    es: "Pagina no encontrada",
    en: "Page not found",
  },
  notFound404__msg1: {
    es: "Esta pagina no esta disponible o no tiene los permisos necesarios",
    en: "This page is not available or does not have the necessary permissions",
  },
  months: {
    en: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    es: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  },
  filterStage: {
    es: "Filtrar Etapa",
    en: "Filter Stage",
  },
  membershipType: {
    es: "Tipo de Membresía",
    en: "Membership Type",
  },
  type: {
    es: "Tipo",
    en: "Type",
  },
  unlimited: {
    es: "Ilimitado",
    en: "Unlimited",
  },

  membershipBenefits: {
    es: "Beneficios de la Membresía",
    en: "Membership Benefits",
  },
  vipWeeks: {
    es: "VIP Weeks",
    en: "VIP Weeks",
  },
  smartWeeks: {
    es: "Smart Weeks",
    en: "Smart Weeks",
  },
  lastMinuteWeeks: {
    es: "Last Minute Weeks",
    en: "Last Minute Weeks",
  },
  selectAnOption: {
    es: "Selecciona una opción",
    en: "Select an option",
  },
  relationship: {
    es: "Parentesco",
    en: "Relationship",
  },
  relationship__msg1: {
    es: "Solo se acepta cinco (5) beneficiarios",
    en: "Only five (5) beneficiaries are accepted",
  },
  relationship__msg2: {
    es: "Agregar beneficiario",
    en: "Add beneficiary",
  },
  incentives: {
    es: "Incentivos",
    en: "Incentives",
  },
  incentives__msg1: {
    es: "Solo se acepta cinco (5) Incentivos",
    en: "Only five (5) Incentives are accepted",
  },
  travelVoucher: {
    es: "Travel Voucher",
    en: "Travel Voucher",
  },
  discountTravelVoucher: {
    es: "Descuento de Bono de Viaje",
    en: "Discount Travel Voucher",
  },
  newMember: {
    es: "Nuevo Socio",
    en: "New Member",
  },
  validate__msg1: {
    es: `El campo "{{field}}" es requerido`,
    en: `The field "{{field}}" is required`,
  },
  validate__msg2: {
    es: "El asterisco (*) y el borde rojo indica campos obligatorios que deben completarse antes de que puedas continuar.",
    en: "The asterisk (*) and red border indicate required fields that must be completed before you can continue.",
  },
  validationError: {
    es: "Error de Validación",
    en: "Validation Error",
  },
  errorGeneric: {
    es: "Error al crear datos, intente de nuevo o contacte con soporte",
    en: "Error creating data, try again or contact support",
  },
  validate__msg3: {
    es: "Los cotitulares son opcionales al capturar un nuevo contrato. Sin embargo, si decides agregar un cotitular, se requieren tanto el nombre como el apellido.",
    en: `Co-owners are optional when capturing a new contract. However, if you choose to add a co-owner, both the first and last name fields are required.`,
  },
  warning: {
    es: "Aviso",
    en: "Warning",
  },
  validate__msg4: {
    es: "Amigos y Familiares son opcionales. Si decides agregarlos, se requieren los campos de nombre, apellido y relación con el titular/cotitular.",
    en: `Friends and Family are optional. If you choose to add them, the fields for first name, last name, and relationship with the owner/co-owner are required.`,
  },
  validate__msg4__ext1: {
    es: "Valide todos los campos de Friends and Family e intente enviar de nuevo.",
    en: `Validate all Friends and Family fields and try submitting again.`,
  },
  validate__msg5: {
    es: `El campo "{{field}}" requiere mínimo 3 caracteres`,
    en: `The field "{{field}}" requires a minimum of 3 characters`,
  },
  validate__msg6: {
    es: "Los incentivos de ventas y regalos son opcionales. Sin embargo, si se proporcionan, todos los campos deben completarse.",
    en: `Sales incentives and gifts are optional. However, if provided, all fields must be filled out.`,
  },
  okCreateAllCap: {
    es: "Contrato y socios creados con éxito.",
    en: "Contract and members created successfully.",
  },
  successfulOperation: {
    es: "Operación Exitosa",
    en: "Successful Operation",
  },
  listMembers: {
    es: "Listar Socios",
    en: "List Members",
  },
  changeStatusContract__mg1: {
    es: "Cancelar el estado del contrato",
    en: "Cancelar estado del contrato",
  },
  changeStatusContract__mg2: {
    es: "Procesar el estado del contrato",
    en: "Process contract status",
  },
  changeStatusContract__mg3: {
    es: "Pausar el estado del contrato",
    en: "Pause contract status",
  },
  changeStatusContract__mg4: {
    es: "¿Desea cambiar el estado del contrato a {{status}}?",
    en: "Do you want to change the contract status to {{status}}?",
  },
  changeStatusContract__mg5: {
    es: "Estado del contrato cambiado con éxito",
    en: "Contract status changed successfully",
  },
  generateDocuments: {
    es: "Generar documentos",
    en: "Generate documents",
  },
  contractAndOwners: {
    es: "Licencia de Usuario",
    en: "End User License",
  },
  uploadDocuments: {
    es: "Subir documentos",
    en: "Upload documents",
  },
  upload: {
    es: "Subir",
    en: "Upload",
  },
  documentGeneratedSuccessfully: {
    es: "Documento generado con éxito",
    en: "Document generated successfully",
  },
  documentGeneratedError: {
    es: "Error al generar el documento",
    en: "Error generating document",
  },
  documentUploadSuccessfully: {
    es: "Documento subido con éxito",
    en: "Document uploaded successfully",
  },
  documentUploadError: {
    es: "Error al subir el documento",
    en: "Error uploading document",
  },
  listDocuments: {
    es: "Documentos subidos",
    en: "Uploaded Documents",
  },
  clickToUpload: {
    es: "Click para subir",
    en: "Click to upload",
  },
  userNotExists: {
    es: "El usuario no existe",
    en: "Username does not exist",
  },
  errorLogin: {
    es: "Error al iniciar sesión",
    en: "Failed to login",
  },
  wrongPassword: {
    es: "Contraseña errónea",
    en: "Wrong password",
  },
  rgxOnlyTextAndSpace: {
    es: "Solo se acepta letras y espacio",
    en: "Only letters and space are accepted",
  },
  rgxOnlyTextAndGuion: {
    es: "Solo se acepta letras, números y guion (-)",
    en: "Only letters, numbers and hyphen (-) are accepted",
  },
  rgxEmail: {
    es: "Correo electrónico incorrecto",
    en: "Incorrect email",
  },
  rgxCityAndAddress: {
    es: "Solo se acepta letras, números, espacios y comas",
    en: "Only letters, numbers, spaces and commas are accepted",
  },
  rgxZip: {
    es: "Solo acepta números, espacios y guiones",
    en: "Only accepts numbers, spaces and hyphens",
  },
  validate__msg7: {
    es: `El campo "{{field}}" no acepta valores negativos o igual a cero`,
    en: `The field "{{field}}" does not accept negative values ​​or values ​​equal to zero`,
  },
  email__err1: {
    es: "El correo electrónico del titular y co titular no pueden ser iguales",
    en: "The email address of the owner and co-owner cannot be the same",
  },
  email__err2: {
    es: `El correo electrónico "{{email}}" ya esta creado en nuestra base de datos`,
    en: `The email "{{email}}" is already created in our database`,
  },
  paymentLinkStatus: {
    es: "Estado de Enlace",
    en: "Link Status",
  },
  lifetime: {
    es: "Lifetime",
    en: "Lifetime",
  },
  fiendsAndFamily: {
    es: "Friends and Family",
    en: "Friends and Family",
  },
  user: {
    es: "Usuario",
    en: "Username",
  },
  role: {
    es: "Rol",
    en: "Role",
  },
  createdBy: {
    es: "Creado por",
    en: "Created by",
  },
  createUser: {
    es: "Crear usuario",
    en: "Create user",
  },
  password: {
    es: "Contraseña",
    en: "Password",
  },
  createRole: {
    es: "Crear rol",
    en: "Create role",
  },
  createRole__type: {
    es: "Seleccione un tipo de rol",
    en: "Choose a role type",
  },
  createRole__staff: {
    es: "Seleccione los módulos para el Staff",
    en: "Select the modules for the Staff",
  },
  createRole__permissions: {
    es: "Seleccione los permisos",
    en: "Select permissions",
  },
  createRole__club: {
    es: "Seleccione los clubes",
    en: "Select clubs",
  },
  notDefined: {
    es: "No definido",
    en: "Not defined",
  },

  confirm: {
    es: "Confirmar",
    en: "Confirm",
  },
  membership: {
    es: "Membresía",
    en: "Membership",
  },
  weeksVipYear: {
    es: "Semanas VIP por año",
    en: "Vip Weeks per Year",
  },
  weeksSmartYear: {
    es: "Semanas Smart por año",
    en: "Smart Weeks per Year",
  },
  weeksMinuteYear: {
    es: "Semanas de último minuto por año",
    en: "Last Minute Weeks per Year",
  },
  coin: {
    es: "MX",
    en: "USD",
  },
  date: {
    es: "Fecha",
    en: "Date",
  },
  filtersBookings__reference: {
    es: "Buscar por Referencia...",
    en: "Search by Reference...",
  },
  filtersBookings__resort: {
    es: "Buscar por Resort...",
    en: "Search by Resort...",
  },
  filtersBookings__client: {
    es: "Buscar por Cliente...",
    en: "Search by Client...",
  },
  filtersBookings__user: {
    es: "Buscar por Usuario...",
    en: "Search by User...",
  },
  filtersBookings__agent: {
    es: "Buscar por Agente...",
    en: "Search by Agent...",
  },
  filtersBookings__status: {
    es: "Buscar por Estado...",
    en: "Search by Status...",
  },
  filtersBookings__date: {
    es: "Buscar por Fecha...",
    en: "Search by Date...",
  },
  filtersBookings__checkIn: {
    es: "Buscar por CheckIn...",
    en: "Search by CheckIn...",
  },
  filtersBookings__loginId: {
    es: "Buscar por LoginID...",
    en: "Search by LoginID...",
  },
  filtersBookings__country: {
    es: "Buscar por País del Resort...",
    en: "Search by Resort Country...",
  },
  filtersBookings__city: {
    es: "Buscar por Ciudad del Resort...",
    en: "Search by Resort City...",
  },
  reserveDetails: {
    es: "Detalles de la Semana",
    en: "Week Details",
  },
  lytc: {
    es: "Licencia de Usuario",
    en: "End User License",
  },
  cont: {
    es: "Contrato",
    en: "Contract",
  },
  additionalPayments: {
    es: "Pagos Adicionales",
    en: "Additional Payments",
  },
  priceContract: {
    es: "Precio de Venta",
    en: "Price",
  },
  administrationCost: {
    es: "Costo Administrativo",
    en: "Contract Cost",
  },
  initialPayment: {
    es: "Pago Inicial",
    en: "Initial Payment",
  },
  downPaymentPercentage: {
    es: "Porcentaje de Pago Inicial",
    en: "Down Payment Percentage",
  },
  balance: {
    es: "Saldo",
    en: "Balance",
  },
  monthlyPayments: {
    es: "Número de Mensualidades",
    en: "Monthly Installments",
  },
  monthlyPayment: {
    es: "Pago mensual",
    en: "Monthly payment",
  },
  firstMonthlyDate: {
    es: "Fecha primera mensualidad",
    en: "First Monthly Payment",
  },
  interestRate: {
    es: "Interés",
    en: "Interest",
  },
  conditionalDownPayment: {
    es: "Pago Inicial Condicional",
    en: "Conditional Down Payment",
  },
  conditionalDownPayment__msg1: {
    es: "Solo se acepta cinco (3) pagos iniciales condicionales",
    en: "Only five (3) conditional initial payments are accepted",
  },
  additionalPayments: {
    es: "Pagos Adicionales",
    en: "Additional Down Payments",
  },
  additionalPayments__msg1: {
    es: "Solo se acepta cinco (3) pagos iniciales",
    en: "Only five (3) initial payments are accepted",
  },
  detailsContract: {
    es: "Detalles del Contrato",
    en: "Contract Details",
  },
  fraf: {
    es: "Friends and Family",
    en: "Friends and Family",
  },
  adp: {
    es: "ADP",
    en: "ADP",
  },
  cdp: {
    es: "CDP",
    en: "CDP",
  },
  acre: {
    es: "ADP",
    en: "ADP",
  },
  ocvi: {
    es: "Compra de de Villa",
    en: "Villa Purchase",
  },
  opra: {
    es: "Cash Out",
    en: "Cash Out",
  },
  paga: {
    es: "Pagaré",
    en: "Promissory Note",
  },
  reba: {
    es: "CDP",
    en: "CDP",
  },
  allFieldsAreRequired: {
    es: "Todos los campos son requeridos",
    en: "All fields are required",
  },
  fieldsComplete: {
    es: "¿Deseas activar la membresía ahora?",
    en: "Do you want to activate membership now?",
  },
  fieldsIncomplete: {
    es: "¿Seguro que quieres activar? Faltan los siguientes campos por completar",
    en: "Are you sure you want to activate? The following fields are missing to complete",
  },
  promotions: {
    es: "Promociónes",
    en: "Promotions",
  },
  promotionCode: {
    es: "Código Promocion",
    en: "Promotion Code",
  },
  form: {
    es: "Registrar F&F",
    en: "F&F Register",
  },
  confirmChangeStatusContract: {
    es: "¿Seguro que quieres cambiar el estado del contrato?",
    en: "Are you sure you want to change the contract status?",
  },
  chooseRelationship: {
    es: "Selecciona el parentesco",
    en: "Select the relationship",
  },
  changeLenguage: {
    es: "Cambiar idioma",
    en: "Change language",
  },
  options: {
    es: "Opciones",
    en: "Options",
  },
  selectAnOption_: {
    es: "Seleccione una opción para recibir el link",
    en: "Select an option to receive the link",
  },
  selectType: {
    es: "Seleccione un metodo",
    en: "Select a method",
  },
  /* Seccion de statistic */
  renewalsReporting: {
    es: "Reportería de Renovaciones",
    en: "Renewals Reporting",
  },
  recoveryTeam: {
    es: "Recuperación de Cuenta",
    en: "Recovery Team",
  },
  totalWeklyProduction: {
    es: "Producción Semanal Total",
    en: "Weekly Total Production",
  },
  salesVolume: {
    es: "Volumen de Ventas",
    en: "Sales Volume",
  },
  appliedPromotions: {
    es: "Promociones Aplicadas",
    en: "Applied Promotions",
  },
  apply: {
    es: "Aplicar",
    en: "Apply",
  },
  delete: {
    es: "Borrar",
    en: "Delete",
  },
  averageTicket: {
    es: "Ticket Promedio",
    en: "Average Ticket",
  },
  conversionRate: {
    es: "Tasa de Conversión",
    en: "Conversion Rate",
  },
  yearsTicket: {
    es: "Años por Ticket",
    en: "Years per Ticket",
  },
  newClinets: {
    es: "Nuevos Clientes",
    en: "New Clients",
  },
  retentionRate: {
    es: "Tasa de Retención",
    en: "Retention Rate",
  },
  filterByDate: {
    es: "Filtrar por fecha",
    en: "Filter by date",
  },
  renewalPeymentLink: {
    es: "Enlace de Pago de Renovaciones",
    en: "Recovery Payment Link",
  },
  paymantDetails: {
    es: "Detalles del Pago",
    en: "Payment Details",
  },
  /* Equipo de recuperación de cuentas */
  recoveredAccounts: {
    es: "Cuentas Recuperadas",
    en: "Recovered Accounts",
  },
  recoveryRate: {
    es: "Tasa de Recuperación",
    en: "Recovery Rate",
  },
  yearsForgiven: {
    es: "Años Condonados",
    en: "Years forgiven",
  },
  amountRecovered: {
    es: "Monto Recuperado",
    en: "Amount Recovered",
  },
  averageRecoveryTime: {
    es: "Tiempo Promedio de Recuperación",
    en: "Average Recovery Time",
  },
  analysisofExpiryReasons: {
    es: "Análisis de Razones de Vencimiento",
    en: "Analysis of Expiry Reasons",
  },
  markAll: {
    es: "Marcar todos",
    en: "Mark all",
  },
  unmarkAll: {
    es: "Desmarcar todos",
    en: "Unmark all",
  },
  selectAll: {
    es: "Seleccionar todos",
    en: "Select all",
  },
  unselectAll: {
    es: "Deseleccionar todos",
    en: "Unselect all",
  },
  reasign: {
    es: "Reasignar",
    en: "Reassign",
  },
  assignedAt: {
    es: "Fecha de asignacion",
    en: "Asign date",
  },
  reassignment: {
    es: "Reasignación",
    en: "Reassignment",
  },
  noMembersFound: {
    es: "No se encontraron socios, revise los filtros de búsqueda",
    en: "No members found, check your search filters",
  },
  discard: {
    es: "Descartar",
    en: "Discard",
  },
  upDownContract: {
    es: "Upgrade/Downgrade",
    en: "Upgrade/Downgrade",
  },
  questionPreAction: {
    es: "¿Desea realizar esta acción?",
    en: "Do you want to perform this action?",
  },
  MonthlyInterestRate: {
    es: "Interés Mensual",
    en: "Monthly Interest",
  },
  remove: {
    es: "Eliminar",
    en: "Remove",
  },
  validate__msg8_numberContract: {
    es: `El Numero de Contrato ya existe`,
    en: `The Contract Number already exists`,
  },
  validate__msg9_loginId: {
    es: `El LoginID ya existe`,
    en: `The LoginID already exists`,
  },
  /*Actions Member*/
  loading: {
    es: "Cargando...",
    en: "Loading...",
  },
  sendWelcomeEmail: {
    es: "Enviar Correo de Bienvenida",
    en: "Send Welcome Email",
  },
  descSendWelcmomeEmail: {
    es: "Enviar un correo electrónico de bienvenida al activar la membresía",
    en: "Send a welcome email upon membership activation",
  },
  sendPasswordRecoveryEmail: {
    es: "Enviar Correo de Recuperación de Contraseña",
    en: "Send Password Recovery Email",
  },
  descPasswordRecoveryEmail: {
    es: "Enviar un correo electrónico de recuperación de contraseña al email del miembro",
    en: "Send a password recovery email to the member's email address",
  },
  sendCustomEmail: {
    es: "Enviar Correo Personalizado",
    en: "Send Custom Email",
  },
  setSequenceEmails: {
    es: "Establecer Secuencia Correos",
    en: "Set Sequence Emails",
  },
  cancelSequenceEmails: {
    es: "Cancelar Secuencia de Correos",
    en: "Cancel Sequence Emails",
  },
  sendCampaignEmail: {
    es: "Enviar Campaña de Correos",
    en: "Send Campaign Emails",
  },
  validate__msg10_saldo: {
    es: `El saldo no puede ser menor a cero`,
    en: `The balance cannot be less than zero`,
  },
  validate__msg11_cdp: {
    es: `En los CDP, el Monto y Fecha son obligatorios`,
    en: `In CDPs, the Amount and Date are mandatory`,
  },
  validate__msg12_adp: {
    es: `En los ADP, el Monto y Fecha son obligatorios`,
    en: `In ADPs, the Amount and Date are mandatory`,
  },
  discountCodes: {
    es: "Códigos de descuento",
    en: "Discount codes",
  },
  catalogs: {
    es: "Catálogos",
    en: "Catalogs",
  },
  fileName: {
    es: "Nombre del Archivo",
    en: "File Name",
  },
  fileformat: {
    es: "Formato ",
    en: "Format",
  },
  dateUpload: {
    es: "Fecha de Subida",
    en: "Date Upload",
  },
  pendingActivation: {
    es: "Pendiente por Activar",
    en: "Pending Activation",
  },
  confirmActivation: {
    es: "Confirmar activación",
    en: "Confirm activation",
  },
  membershipActivatedOk: {
    es: "Membresía activada con éxito",
    en: "Membership activated successfully",
  },
  document: {
    es: "Documento",
    en: "Document",
  },
  uploadedBy: {
    es: "Subido por",
    en: "Uploaded by",
  },
  uploadDocument: {
    es: "Subir documento",
    en: "Upload document",
  },
  downloadDocument: {
    es: "Descargar documento",
    en: "Download document",
  },
  removeDocument: {
    es: "Eliminar documento",
    en: "Remove document",
  },
  pendingUpload: {
    es: "Pendiente por Subir",
    en: "Pending Upload",
  },
  uploadedDocument: {
    es: "Documento Subido",
    en: "Uploaded Document",
  },
  generateDocument: {
    es: "Generar Documento",
    en: "Generate Document",
  },
  documentRemovedSuccessfully: {
    es: "Documento eliminado con éxito",
    en: "Document successfully deleted",
  },
  documentRemovedError: {
    es: "Error al eliminar el documento",
    en: "Error deleting document",
  },
  activateMembership: {
    es: "Activar membresía",
    en: "Activate membership",
  },
  validateMemberData: {
    es: "Validar datos de contacto",
    en: "Validate contact details",
  },
  validateMemberData__mgs1: {
    es: "Por favor, valida que la información capturada sea correcta",
    en: "Please validate that the information captured is correct",
  },
  continue: {
    es: "Continuar",
    en: "Continue",
  },
  correct: {
    es: "Corregir",
    en: "Correct",
  },
  createRole__msg1: {
    es: "Rol creado con éxito",
    en: "Successfully created role",
  },
  closingCost: {
    es: "Gastos Legales",
    en: "Closing Cost",
  },
  authorizationPayments: {
    es: "Autorización para Pagos Mensuales",
    en: "Authorization for Monthly Payments",
  },
  upgrade: {
    es: "Upgrade",
    en: "Upgrade",
  },
  downgrade: {
    es: "Downgrade",
    en: "Downgrade",
  },
  upgradeDowngradeOk: {
    es: "Upgrade/Downgrade exitoso",
    en: "Upgrade/Downgrade successful",
  },
  processingFee: {
    es: "Cuota de Procesamiento",
    en: "Processing Fee",
  },
  sixTax: {
    es: "6% Impuestos",
    en: "6% Occupancy Tax",
  },
  total: {
    es: "Total",
    en: "Total",
  },
  seller: {
    es: "Vendedor",
    en: "Seller",
  },
  changeLanguageContract: {
    es: "¿Desea cambiar el idioma del contrato?",
    en: "Do you want to change the language of the contract?",
  },
  changeLanguageContract__msg1: {
    es: "Idioma cambiado con éxito",
    en: "Language changed successfully",
  },
  allRightReserved: {
    es: "Todos los derechos reservados",
    en: "All rights reserved",
  },
  reportBug: {
    es: "Reportar Error",
    en: "Report Bug",
  },
  changeLenguage__msg1: {
    es: "Presiona para cambiar el idioma",
    en: "Press to change the language",
  },
  reportBug__msg1: {
    es: "Grabando Error...",
    en: "Recording Error...",
  },
  reportBug__msg2: {
    es: "Detener grabación",
    en: "Stop recording",
  },
  reportBug__msg3: {
    es: "A continuación se habilitará el modo de <b>grabar pantalla</b> para reportar el error, una vez escriba una breve descripción",
    en: "Next, the <b>screen recording</b> mode will be enabled to report the error, once write a short description",
  },
  iUnderstand: {
    es: "¡Lo entiendo!",
    en: "I understand!",
  },
  reportBug__msg4: {
    es: "Escribe una breve descripción del error: ",
    en: "Write a brief description of the bug:",
  },
  reportBug__msg5: {
    es: "Explica de manera breve el error...",
    en: "Briefly explain the error...",
  },
  reportBug__msg6: {
    es: "Error reportado con éxito",
    en: "Error reported successfully",
  },
  assigned: {
    es: "Asignado",
    en: "Assigned",
  },
  renewalAssignRemove: {
    es: "Eliminar asignaciones",
    en: "Delete assignments",
  },
  totalSelected: {
    es: "Total seleccionados",
    en: "Total selected",
  },
  sendLinkPayment: {
    es: "Enviar Enlace de Pago",
    en: "Send Payment Link",
  },
  notFound: {
    es: "No encontrado",
    en: "Not found",
  },
  syncContactInBitrix: {
    es: "Sincronizar contacto en Bitrix24",
    en: "Sync contact in Bitrix24",
  },
  syncContactInBitrix_msg1: {
    es: "Crea el contacto en Bitrix24",
    en: "Create the contact in Bitrix24",
  },
  syncContactInBitrix_msg2: {
    es: "Contacto creado con éxito",
    en: "Contact created successfully",
  },
  priceTotal: {
    es: "Precio Total",
    en: "Total Price",
  },
  contact: {
    es: "Contacto",
    en: "Contact",
  },
  reputation: {
    es: "Reputación",
    en: "Reputation",
  },
  nights: {
    es: "Noches",
    en: "Nights",
  },
  kitchen: {
    es: "Cocina",
    en: "Kitchen",
  },
  occupancyMax: {
    es: "Ocupación Max.",
    en: "Max. Occupancy",
  },
  people: {
    es: "Personas",
    en: "People",
  },
  notesImportant: {
    es: "Notas Importantes",
    en: "Important Notes",
  },
  duration: {
    es: "Duración",
    en: "Duration",
  },
  price: {
    es: "Precio",
    en: "Price",
  },
  discountCode: {
    es: "Código de descuento",
    en: "Discount code",
  },
  discount: {
    es: "Descuento",
    en: "Discount",
  },
  create: {
    es: "Crear",
    en: "Create",
  },
  paymentLink__msg1: {
    es: "Ya existe un enlace de pago",
    en: "A payment link already exists",
  },
  copyPaymentLink: {
    es: "Copiar enlace de pago",
    en: "Copy payment link",
  },
  copyPaymentLink__msg1: {
    es: "Enlace de pago copiado con éxito",
    en: "Payment link successfully copied",
  },
  sendLinkPayment__msg1: {
    es: "Enlace de pago reenviado con éxito",
    en: "Payment link successfully resent",
  },
  sendLinkPayment__msg2: {
    es: "Error al reenviar enlace de pago",
    en: "Error resending payment link",
  },
  cancelLinkPayment: {
    es: "Cancelar enlace de pago",
    en: "Cancel payment link",
  },
  cancelLinkPayment__msg1: {
    es: "Enlace de pago cancelado con éxito",
    en: "Payment link successfully cancelled",
  },
  cancelLinkPayment__msg2: {
    es: "Error al cancelar enlace de pago",
    en: "Error cancelling payment link",
  },
  sendMailToRenewal: {
    es: "Enviar correo a",
    en: "Send email to",
  },
  both: {
    es: "Ambos",
    en: "Both",
  },
  forgiveDebt: {
    es: "Condonar deuda",
    en: "Forgive debt",
  },
  login: {
    es: "Iniciar Sesión",
    en: "Log In",
  },
  paymentLink__paid: {
    es: "Pago realizado con éxito",
    en: "Payment made successfully",
  },
  paymentLink__canceled: {
    es: "Pago cancelado o expirado",
    en: "Payment link canceled or expired",
  },
  externalPayment: {
    es: "Pago Externo",
    en: "External Payment",
  },
  externalPayment__msg1: {
    es: "Pago externo creado con éxito",
    en: "External payment created successfully",
  },
  externalPayment__msg2: {
    es: "Error al crear pago externo",
    en: "Error creating external payment",
  },
  yearsCondoned: {
    es: "Años Condonados",
    en: "Years Condoned",
  },
  newPeriod: {
    es: "Nuevo Periodo",
    en: "New Period",
  },
  condoned: {
    es: "Condonados",
    en: "Condoned",
  },
  noAnnuities: {
    es: "Sin anualidades",
    en: "No annuities",
  },
  addPaymentDeferred: {
    es: "Agregar Pago Diferido",
    en: "Add Deferred Payment",
  },
  initialPayment: {
    es: "Abono inicial",
    en: "Initial Payment",
  },
  totalPayment: {
    es: "Total Pago",
    en: "Total Payment",
  },
  addPaymentDeferred__msg1: {
    es: "Pago diferido creado con éxito",
    en: "Deferred payment created successfully",
  },
  addPaymentDeferred__msg2: {
    es: "Error al crear pago diferido",
    en: "Error creating deferred payment",
  },
  paymentsDeferred: {
    es: "Pagos diferidos",
    en: "Deferred payments",
  },
  amountPaid: {
    es: "Monto pagado",
    en: "Amount paid",
  },
  totalToPayment: {
    es: "Total a Pagar",
    en: "Total to Pay",
  },
  completePayment: {
    es: "Completar Pago",
    en: "Complete Payment",
  },
  missingAmount: {
    es: "Monto Faltante",
    en: "Missing Amount",
  },
  paymentCompleted: {
    es: "Pago Completado",
    en: "Payment Completed",
  },
  amountToPay: {
    es: "Monto a Pagar",
    en: "Amount to Pay",
  },
  amountToPay__msg1: {
    es: "El monto a pagar es menor al monto faltante",
    en: "The amount to pay is less than the missing amount",
  },
  webTemporary: {
    es: "Web temporal",
    en: "Temporary web",
  },
  webTemporary__msg1: {
    es: "El acceso web temporal se ha actualizado con éxito",
    en: "The temporary web access has been updated successfully",
  },
  allowAccess: {
    es: "Permitir acceso",
    en: "Allow access",
  },
  allow: {
    es: "Permitir",
    en: "Allow",
  },
  notAllow: {
    es: "No permitir",
    en: "Not allow",
  },
  paymentLinkDeferred: {
    es: "Enlace de Pago Diferido",
    en: "Payment Link Deferred",
  },
  linkType: {
    es: "Tipo de Enlace",
    en: "Link Type",
  },
  deferred: {
    es: "Diferido",
    en: "Deferred",
  },
  notDeferred: {
    es: "Individual/Online",
    en: "Individual/Online",
  },
  subtype: {
    es: "Subtipo",
    en: "Subtype",
  },
  renewalStatistics: {
    es: "Estadísticas de Renovaciones",
    en: "Renewals Statistics",
  },
  totals: {
    es: "Totales",
    en: "Totals",
  },
  renewedYears: {
    es: "Años Renovados",
    en: "Renewed Years",
  },
  totalsPerYear: {
    es: "Totales por año",
    en: "Totals per Year",
  },
  shippingByAgent: {
    es: "Envíos por Agente",
    en: "Shipping by Agent",
  },
  shipments: {
    es: "Envíos",
    en: "Sent",
  },
  totalsPerAgent: {
    es: "Totales por Agente",
    en: "Totals per Agent",
  },
  multiple: {
    es: "Múltiple",
    en: "Multiple",
  },
  average: {
    es: "Promedio",
    en: "Average",
  },
  yearsToRenew: {
    es: "Años por renovar",
    en: "Years to renew",
  },
  deferredPaymentsPerAgent: {
    es: "Pagos diferidos por agente",
    en: "Deferred payments per agent",
  },
  sendEmailRenewals: {
    es: "¿Deseas enviar un total de {{total}} correos de renovación online?",
    en: "Do you want to send a total of {{total}} online renewal emails?",
  },
  sendLinkPaymentSelected: {
    es: "Enviar enlace de pago a seleccionados",
    en: "Send payment link to selected",
  },
  sendLinkPaymentAll: {
    es: "Enviar enlace de pago a todos",
    en: "Send payment link to all",
  },
  sendEmailRenewalsAll: {
    es: "¿Deseas a todos los de esta lista los correos de renovación online?",
    en: "Do you want everyone on this list to receive online renewal emails?",
  },
  shippingStatus: {
    es: "Estado de Envío",
    en: "Shipping Status",
  },
  shippingDate: {
    es: "Fecha de Envío",
    en: "Shipping Date",
  },
  onlineRenewalsSent__title: {
    es: "Renovaciones Online Enviadas",
    en: "Online Renewals Sent",
  },
  onlineRenewalsSent__msg1: {
    es: "Cada envió entrara en una cola, puede revisar el estado una vez finalizada la cola.",
    en: "Each sent will be in a queue, you can check the status once the queue is finished.",
  },
  other: {
    es: "Otro",
    en: "Other",
  },
  loginIdFound: {
    es: "LoginID encontrado",
    en: "LoginID found",
  },
  traveler: {
    es: "Viajero",
    en: "Traveler",
  },
  applyLoginId__fail: {
    es: "Aplique un LoginID",
    en: "Apply a LoginID",
  },
  applyLoginId__ok: {
    es: "LoginID aplicado",
    en: "LoginID applied",
  },
  applyLoginId__msg1: {
    es: "¿Desea aplicar este LoginID?",
    en: "Want to apply this LoginID?",
  },
  vrsys: {
    es: "VRSYS",
    en: "VRSYS",
  },
  concentrated: {
    es: "Concentrado",
    en: "Concentrated",
  },
  detailed: {
    es: "Detallado",
    en: "Detailed",
  },
  folio: {
    es: "Folio",
    en: "Folio",
  },
  exportToCsv__msg1: {
    es: "¿Desea exportar los resultados a un archivo CSV?",
    en: "Do you want to export the results to a CSV file?",
  },
  volumenRenewal: {
    es: "{{renewals}} Renovaciones pagadas con un total de $ {{total}} USD.",
    en: "{{renewals}} Renewals paid with a total of $ {{total}} USD.",
  },
  RenewalsSvailableToShip: {
    es: "Renovaciones Disponibles para Enviar",
    en: "Renewals Available to Ship",
  },
  TheLimitIsResetTo: {
    es: "El límite se restablece",
    en: "The limit is reset",
  },
  ownerAndCoOwner: {
    es: "Titular y Co-Titular",
    en: "Owner and Co-Owner",
  },
  editContract: {
    es: "Editar Contrato",
    en: "Edit Contract",
  },
  editContract__msg1: {
    es: "Contrato editado con éxito",
    en: "Contract edited successfully",
  },
  addCoOwner: {
    es: "Añadir Co-Titular",
    en: "Add Co-Owner",
  },
  addCoOwner__msg1: {
    es: "Co-Titular añadido con éxito",
    en: "Co-Owner added successfully",
  },
  addCoOwner__msg2: {
    es: "Error al añadir Co-Titular",
    en: "Error adding Co-Owner",
  },
  deleteCoOwner: {
    es: "Eliminar co-titular",
    en: "Delete co-owner",
  },
  deleteCoOwner__msg1: {
    es: "Co-Titular eliminado con éxito",
    en: "Co-Owner deleted successfully",
  },
  deleteCoOwner__msg2: {
    es: "Error al eliminar Co-Titular",
    en: "Error deleting Co-Owner",
  },
  deleteCoOwner__msg3: {
    es: "¿Desea eliminar el co-titular?",
    en: "Do you want to delete the co-owner?",
  }
};
